/* scss-combine:common/functions */
/* scss-combine:common/variables */
/* scss-combine:common/mixins */
.home-section .featured-text h1 {
  color: #B78D41;
  font-family: "PT Serif","Times New Roman",Times,serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33px; }

/* scss-combine:common/common-classes */
.uc-title, .main-footer .connect .title, .main-header-wrap .main-header .store-title, .main-header-wrap .main-header .mini-cart .brand, .main-header nav.full .nav-item > .label, .mega-nav .list .label, .mobile-dropdown .list .list-item a, .sidebar .module > label, .sidebar .recent-posts .date, .sidebar .tags-list .tag .count, .sidebar .twitter .date, .collections .collection .browse, .featured-collections .collection .browse, .product .overlay .label, .product .brand, .quick-shop-content .brand, #product-area .brand, .mobile-dropdown #mob-coin-container select, .mobile-dropdown #mob-coin-container label, .customer .header-link, .customer.account .title, .template-blog .articles .meta li label, .template-article .articles .meta li label, .template-article .articles .comments-wrap .title, .template-article .articles .comments-wrap.read .comment .date, .template-cart .table td.product-item .label.vendor, .template-index .slide .content .call-to-action, .template-index .article .date, .twitter-tweet .timestamp, .customer.order .order-history .table td.product-item .label.vendor, .template-page .sidebar .title, .template-contact .sidebar .title, .password-page-logo .store-title, #product-area .product-details-wrapper .description .go-to-product, .quick-shop-content .product-details-wrapper .description .go-to-product, .template-search .result.item-article .date, .template-search .result.item-product .details .brand {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.select-wrapper {
  position: relative;
  width: 235px;
  background-color: #d6d4d4;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 1px #5c5555;
  display: inline-block;
  color: black;
  font-size: 12px;
  padding: 8px 10px;
  font-family: "Raleway", sans-serif;
  cursor: pointer; }
  .select-wrapper:before {
    content: "";
    display: block;
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 38px;
    background: #5c5555;
    border-radius: 0px 4px 4px 0px; }
  .select-wrapper:after {
    content: "";
    display: block;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #aeaaaa transparent transparent transparent;
    background: none; }
  .select-wrapper label.select-text {
    width: 100%;
    margin: 0;
    max-width: 82%;
    text-align: left;
    color: #000000 !important; }
  .select-wrapper select {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    color: #000;
    cursor: pointer;
    opacity: 0;
    -webkit-appearance: none; }

.empty {
  font-size: 16px;
  margin: 40px 0 60px;
  font-style: italic; }
  @media (max-width: 719px) {
    .empty {
      width: 100%;
      text-align: center;
      padding: 0 30px; } }
  .empty a {
    color: #5d5b57; }
    .empty a:hover {
      color: #060606; }

.error-message, .alert-message {
  font-family: 'Opens Sans', sans-serif;
  background-color: #FBEDEC;
  color: #ee575a;
  font-size: 12px;
  border: 1px solid #EA555C;
  height: 42px;
  width: 75%; }
  .error-message.success, .alert-message.success {
    border-color: #4faa2a;
    background-color: #f2f9ed;
    color: #66b544; }
  @media (max-width: 719px) {
    .error-message, .alert-message {
      width: 100%; } }

.content-area {
  display: block;
  margin: 0 auto;
  padding: 0 15px;
  min-width: 690px;
  max-width: 1430px;
  width: 84%; }
  @media (max-width: 719px) {
    .content-area {
      min-width: 290px;
      width: 100%; } }

.section-title {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0 0 45px;
  padding: 45px 0 0;
  color: #b38d41; }

.section-border {
  border-top: 1px solid #5c5555; }
  main section:first-child .section-border {
    border-top: 0; }

.action-button {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #f9f4ee;
  background-color: #5d5b57;
  padding: 9px 14px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block; }
  .action-button:hover {
    background-color: #060606;
    color: #f9f4ee; }
  .action-button.desaturated {
    background-color: #5c5555; }
  .action-button.desaturated:hover {
    background-color: #cccaca; }

.field {
  border: 1px solid #5c5555;
  color: #362c2c;
  font-family: "Raleway", sans-serif;
  outline: none;
  font-size: 12px;
  padding: 8px 12px;
  display: inline-block;
  -webkit-transition: box-shadow 250ms, border 250ms, color 250ms;
  -moz-transition: box-shadow 250ms, border 250ms, color 250ms;
  transition: box-shadow 250ms, border 250ms, color 250ms; }
  .field.error {
    border: 1px solid #F3AAAD;
    color: #ee575a;
    box-shadow: 0px 0px 7px rgba(238, 87, 90, 0.4); }
    .field.error::-webkit-input-placeholder {
      color: #ee575a; }
    .field.error:-moz-placeholder {
      color: #ee575a; }
    .field.error::-moz-placeholder {
      color: #ee575a; }
    .field.error:-ms-input-placeholder {
      color: #ee575a; }

.page-title {
  padding: 16px 0 35px;
  border-bottom: 1px solid #5c5555; }
  @media (max-width: 719px) {
    .page-title {
      padding: 35px 0;
      text-align: center;
      border-bottom: none; } }
  .page-title .label {
    font-size: 35px;
    font-weight: 700;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    color: #b38d41; }
    .page-title .label a {
      color: #b38d41; }
    @media (max-width: 719px) {
      .page-title .label {
        display: block;
        white-space: pre-line; } }

.load-up {
  padding: 0 30px;
  font-size: 14px;
  color: #aeaaaa; }
  .featured-collections .load-up {
    padding-bottom: 65px; }

.centering-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.placeholder-svg {
  min-height: 25vh; }
  .slideshow .placeholder-svg {
    min-height: 66vh; }
    @media screen and (max-width: 720px) {
      .slideshow .placeholder-svg {
        min-height: 25vh; } }

.placeholder-background {
  background-color: #f2f2f2; }

.placeholder-svg {
  background-color: #f2f2f2;
  fill: #5c5555; }

/* scss-combine:common/fonts */
@font-face {
  font-family: 'icons';
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/atlantic-icons.eot?v=10151495640851826001579199161");
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/atlantic-icons.eot?v=10151495640851826001579199161?#iefix") format("embedded-opentype"), url("//isabellaadams.com/cdn/shop/t/8/assets/atlantic-icons.woff?v=75156632991815219611579199162") format("woff"), url("//isabellaadams.com/cdn/shop/t/8/assets/atlantic-icons.ttf?v=146308269215394365301579199161") format("truetype"), url("//isabellaadams.com/cdn/shop/t/8/assets/atlantic-icons.svg?526#atlantic-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TheanoDidotRegular';
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/TheanoDidot-Regular-webfont.eot?v=50708852979560860291579199159");
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/TheanoDidot-Regular-webfont.eot?v=50708852979560860291579199159#iefix") format("embedded-opentype"), url("//isabellaadams.com/cdn/shop/t/8/assets/TheanoDidot-Regular-webfont.woff?v=85276413968498852281579199160") format("woff"), url("//isabellaadams.com/cdn/shop/t/8/assets/TheanoDidot-Regular-webfont.ttf?v=124649612153300403021579199159") format("truetype"), url("//isabellaadams.com/cdn/shop/t/8/assets/TheanoDidot-Regular-webfont.svg?526#TheanoDidotRegular") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'butlerbold';
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.eot?v=175128527276906531181579199165");
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.eot?%23iefix&v=175128527276906531181579199165") format("embedded-opentype"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.woff2?v=114054217314575204571579199167") format("woff2"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.woff?v=164628345862631112861579199166") format("woff"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.ttf?v=113464741923868806031579199165") format("truetype"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_bold-webfont.svg%23butlerbold?526") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'butlermedium';
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/butler_medium-webfont.eot?v=107194244227594586031579199167");
  src: url("//isabellaadams.com/cdn/shop/t/8/assets/butler_medium-webfont.eot?%23iefix&v=107194244227594586031579199167") format("embedded-opentype"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_medium-webfont.woff2?v=156766793179143037091579199169") format("woff2"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_medium-webfont.woff?v=7834473894184870141579199168") format("woff"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_medium-webfont.ttf?v=68582159388779932931579199168") format("truetype"), url("//isabellaadams.com/cdn/shop/t/8/assets/butler_mediutm-webfont.svg%23butlermedium?526") format("svg");
  font-weight: normal;
  font-style: normal; }

/* scss-combine:common/footer */
.main-footer {
  padding: 0 15px;
  margin-top: 65px; }
  .main-footer .footer-message,
  .main-footer .email {
    color: #aeaaaa;
    font-size: 14px; }
    .main-footer .footer-message a,
    .main-footer .email a {
      color: #aeaaaa; }
      .main-footer .footer-message a:hover,
      .main-footer .email a:hover {
        color: #9d9999; }
  .main-footer .connect {
    display: block;
    text-align: center;
    border-top: 1px solid #5c5555;
    border-bottom: 1px solid #5c5555;
    font-size: 0; }
    .main-footer .connect > li {
      width: 33.3333%;
      height: 185px;
      border-left: 1px solid #5c5555;
      text-align: center;
      display: inline-block;
      vertical-align: top; }
      .main-footer .connect > li:first-child {
        border-left: none; }
      @media screen and (max-width: 910px) {
        .main-footer .connect > li {
          width: 100% !important;
          height: auto;
          border-left: none;
          border-top: 1px solid #5c5555; }
          .main-footer .connect > li:first-child {
            border-top: none; } }
    .main-footer .connect.module-count-1 > li, .main-footer .connect.module-count-2 > li {
      width: 50%; }
    .main-footer .connect.module-count-1 > li {
      border-left: 0; }
    .main-footer .connect .title {
      font-size: 14px;
      color: #aeaaaa; }
    .main-footer .connect .contact .title {
      margin-bottom: 35px; }
    .main-footer .connect .social .title {
      margin-bottom: 35px; }
    .main-footer .connect .social > ul {
      padding: 0 20px;
      font-size: 0; }
    .main-footer .connect .social .social-link {
      width: 26px;
      height: 26px;
      margin: 0 8px 0;
      position: relative;
      border-radius: 4px;
      display: inline-block; }
      .main-footer .connect .social .social-link a {
        font-family: 'icons';
        -webkit-font-smoothing: antialiased;
        line-height: 0px;
        font-size: 43px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        color: #aeaaaa; }
        .main-footer .connect .social .social-link a:hover {
          color: #9d9999; }
      .main-footer .connect .social .social-link svg {
        width: 26px;
        height: 26px;
        color: #aeaaaa; }
        .main-footer .connect .social .social-link svg:hover {
          color: #9d9999; }
      .main-footer .connect .social .social-link.twitter a {
        top: 11px;
        font-size: 48px; }
      .main-footer .connect .social .social-link.facebook a {
        top: 12px; }
      .main-footer .connect .social .social-link.pinterest a {
        top: 12px; }
      .main-footer .connect .social .social-link.google-plus a {
        top: 14px;
        font-size: 40px; }
      .main-footer .connect .social .social-link.instagram a {
        top: 12px;
        font-size: 40px; }
      .main-footer .connect .social .social-link.tumblr a {
        top: 12px;
        font-size: 45px; }
      .main-footer .connect .social .social-link.youtube a {
        top: 11px; }
      .main-footer .connect .social .social-link.vimeo a {
        top: 12px; }
    .main-footer .connect .newsletter .title {
      margin-bottom: 36px; }
    .main-footer .connect .newsletter .wrap {
      display: inline-block;
      width: 75%;
      max-width: 267px;
      padding-right: 65px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 910px) {
        .main-footer .connect .newsletter .wrap {
          width: 210px; } }
    .main-footer .connect .newsletter .email, .main-footer .connect .newsletter .submit {
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      font-family: "Raleway", sans-serif; }
    .main-footer .connect .newsletter .email {
      border: 1px solid #5c5555;
      padding: 8px 0 8px 8px;
      width: calc(100% - 16px);
      background: transparent;
      font-size: 12px;
      font-family: "Raleway", sans-serif;
      color: #aeaaaa; }
    .main-footer .connect .newsletter .submit {
      position: absolute;
      top: -1px;
      right: 0px;
      bottom: 0px;
      width: 65px;
      background: #222222;
      font-size: 10px;
      letter-spacing: 0.1em;
      color: white;
      text-transform: uppercase;
      text-align: center;
      border-radius: 0px; }
      .main-footer .connect .newsletter .submit:hover {
        background: #b38d41; }
  .main-footer .sub-footer {
    margin: 40px 0;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa; }
    @media screen and (max-width: 910px) {
      .main-footer .sub-footer {
        text-align: center; } }
    .main-footer .sub-footer nav {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 0 30px; }
      .main-footer .sub-footer nav li {
        display: inline-block;
        margin: 0 10px 10px; }
      .main-footer .sub-footer nav a {
        color: #aeaaaa; }
        .main-footer .sub-footer nav a:hover {
          color: #9d9999; }
      .main-footer .sub-footer nav svg {
        width: 25px;
        height: 25px;
        color: #b38d41; }
      .main-footer .sub-footer nav .social-link:not(:last-of-type) {
        margin-right: 16px; }
    .main-footer .sub-footer p {
      text-align: center;
      margin: 50px 0 30px; }
      @media screen and (max-width: 910px) {
        .main-footer .sub-footer p {
          display: inline-block;
          margin: 10px 0 15px; } }
      .main-footer .sub-footer p a {
        color: #aeaaaa; }
      .main-footer .sub-footer p a:hover {
        color: #9d9999; }
    .main-footer .sub-footer nav ~ p {
      margin-top: 20px; }
    .main-footer .sub-footer .payment-options {
      margin: 10px 0 50px;
      display: block;
      text-align: center; }
      .main-footer .sub-footer .payment-options li {
        display: inline-block;
        width: 55px;
        height: 34px;
        opacity: 0.2;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
        filter: alpha(opacity=20); }
      .main-footer .sub-footer .payment-options svg {
        width: 55px;
        height: 34px; }

#main-footer {
  line-height: 22px; }
  #main-footer .module-count-0 {
    border-bottom: 0; }
  #main-footer .newsletter,
  #main-footer .contact,
  #main-footer .footer-menu {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 1rem;
    color: #aeaaaa; }
    @media screen and (max-width: 910px) {
      #main-footer .newsletter,
      #main-footer .contact,
      #main-footer .footer-menu {
        padding-top: 40px; } }
    #main-footer .newsletter li,
    #main-footer .contact li,
    #main-footer .footer-menu li {
      font-size: 13px;
      line-height: 2em; }
    #main-footer .newsletter a,
    #main-footer .contact a,
    #main-footer .footer-menu a {
      color: currentColor; }
  #main-footer .connect {
    display: table;
    width: 100%; }
    @media screen and (max-width: 910px) {
      #main-footer .connect {
        display: block; } }
    #main-footer .connect > li.first {
      padding-left: 20px;
      padding-right: 20px; }
      @media (min-width: 1000px) {
        #main-footer .connect > li.first {
          padding-left: 40px;
          padding-right: 40px; } }
    #main-footer .connect > li {
      display: table-cell;
      vertical-align: middle; }
    @media screen and (max-width: 910px) {
      #main-footer .connect > li {
        display: block;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 100%; } }

.main-footer-alt .connect .newsletter .wrap {
  width: 100%; }
.main-footer-alt .connect > li:first-of-type {
  border: 0; }
.main-footer-alt .footer-message + form {
  margin-top: 2em; }
.main-footer-alt .module-count-2 > li {
  width: 50%; }
.main-footer-alt .module-count-3 > li.first {
  width: 50%; }
.main-footer-alt .module-count-3 > li:not(.first) {
  width: 25%; }

/* scss-combine:common/general */
a {
  color: #5d5b57; }

a:hover {
  color: #060606; }

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input::-ms-clear {
  display: block; }

input[type="text"], input[type="email"], input[type="number"], input[type="password"], textarea {
  -webkit-appearance: none;
  border-radius: 0px; }
  input[type="text"]::-webkit-input-placeholder, input[type="email"]::-webkit-input-placeholder, input[type="number"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #cccaca; }
  input[type="text"]:-moz-placeholder, input[type="email"]:-moz-placeholder, input[type="number"]:-moz-placeholder, input[type="password"]:-moz-placeholder, textarea:-moz-placeholder {
    color: #cccaca; }
  input[type="text"]::-moz-placeholder, input[type="email"]::-moz-placeholder, input[type="number"]::-moz-placeholder, input[type="password"]::-moz-placeholder, textarea::-moz-placeholder {
    color: #cccaca; }
  input[type="text"]:-ms-input-placeholder, input[type="email"]:-ms-input-placeholder, input[type="number"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #cccaca; }

.lt-ie9 input[type="password"] {
  font-family: Arial, sans-serif !important;
  /* Opens Sans has known bug with passwords fields in IE8 */ }

textarea {
  background-color: white; }

body {
  color: #362c2c;
  font-family: "PT Serif", "Times New Roman", Times, serif;
  background-color: white; }

body,
html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  /* iOS position:fixed; elements fix (not 100%) */
  min-height: 100%;
  overflow-x: hidden; }

/* scss-combine:common/header */
.main-header-wrap {
  position: relative;
  z-index: 5000; }
  .main-header-wrap .main-header {
    position: relative; }
    .main-header-wrap .main-header .store-title {
      display: block;
      float: left;
      clear: left;
      font-size: 28px;
      letter-spacing: 0.2em !important;
      max-width: 48%;
      margin: 0;
      padding: 50px 20px 35px 0px; }
      @media (min-width: 720px) and (max-width: 960px) {
        .main-header-wrap .main-header .store-title {
          font-size: 21px; } }
      @media (max-width: 719px) {
        .main-header-wrap .main-header .store-title {
          font-size: 23px;
          max-width: 100%;
          width: 100%;
          text-align: center;
          padding: 45px 0px 40px; } }
      .main-header-wrap .main-header .store-title img {
        max-height: 200px;
        display: inline-block; }
      .main-header-wrap .main-header .store-title a {
        color: #222222; }
        .main-header-wrap .main-header .store-title a:hover {
          color: #222222; }
      .header-centered .main-header-wrap .main-header .store-title {
        max-width: 100%;
        float: none;
        text-align: center; }
        @media (max-device-width: 1024px), (max-width: 719px) {
          .header-centered .main-header-wrap .main-header .store-title {
            padding: 45px 0; } }
    .main-header-wrap .main-header .action-links {
      padding: 0 15px;
      position: relative;
      z-index: 2000; }
    .main-header-wrap .main-header.header-social-links .social-link:not(:first-of-type) {
      margin-left: 10px; }
    .main-header-wrap .main-header.header-social-links .store-title {
      padding-top: 0px; }
    .main-header-wrap .main-header.header-social-links .social-links {
      float: left;
      margin-bottom: 20px; }
    .main-header-wrap .main-header.header-social-links .tools {
      margin-top: 20px; }
      .header-centered .main-header-wrap .main-header.header-social-links .tools {
        text-align: right; }
        .header-centered .main-header-wrap .main-header.header-social-links .tools .tools-left,
        .header-centered .main-header-wrap .main-header.header-social-links .tools .tools-right {
          float: none; }
    .main-header-wrap .main-header .tools {
      position: relative;
      visibility: hidden;
      float: right;
      max-width: 70%; }
      @media (max-device-width: 1024px), (max-width: 719px) {
        .main-header-wrap .main-header .tools {
          display: none; } }
      .main-header-wrap .main-header .tools .tools-left,
      .main-header-wrap .main-header .tools .tools-right {
        display: inline-block; }
      .header-centered .main-header-wrap .main-header .tools {
        margin-top: 20px;
        float: none;
        max-width: 100%;
        visibility: visible; }
        .header-centered .main-header-wrap .main-header .tools .tools-left {
          float: left; }
          .header-centered .main-header-wrap .main-header .tools .tools-left > *:first-child {
            margin-left: 0; }
        .header-centered .main-header-wrap .main-header .tools .tools-right {
          float: right; }
          .header-centered .main-header-wrap .main-header .tools .tools-right > *:last-child {
            margin-right: 0; }
        .header-centered .main-header-wrap .main-header .tools .header-social-links .tools-right,
        .header-centered .main-header-wrap .main-header .tools .header-social-links .tool-left {
          float: none; }
      .main-header-wrap .main-header .tools .currency-switcher,
      .main-header-wrap .main-header .tools .search,
      .main-header-wrap .main-header .tools .mini-cart-wrap,
      .main-header-wrap .main-header .tools .account-options {
        display: inline-block;
        font-family: "Raleway", sans-serif;
        font-size: 12px;
        color: #b38d41;
        margin: 0 10px;
        position: relative; }
        .main-header-wrap .main-header .tools .currency-switcher:hover, .main-header-wrap .main-header .tools .currency-switcher.active,
        .main-header-wrap .main-header .tools .search:hover,
        .main-header-wrap .main-header .tools .search.active,
        .main-header-wrap .main-header .tools .mini-cart-wrap:hover,
        .main-header-wrap .main-header .tools .mini-cart-wrap.active,
        .main-header-wrap .main-header .tools .account-options:hover,
        .main-header-wrap .main-header .tools .account-options.active {
          color: #999999; }
        .main-header-wrap .main-header .tools .currency-switcher.active,
        .main-header-wrap .main-header .tools .search.active,
        .main-header-wrap .main-header .tools .mini-cart-wrap.active,
        .main-header-wrap .main-header .tools .account-options.active {
          z-index: 5000; }
      .main-header-wrap .main-header .tools .icon {
        font-family: 'icons';
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 0px; }
      .main-header-wrap .main-header .tools .search .icon {
        font-size: 25px;
        top: 8px; }
      .main-header-wrap .main-header .tools .mini-cart-wrap {
        position: relative;
        cursor: pointer; }
        .main-header-wrap .main-header .tools .mini-cart-wrap .icon {
          font-size: 40px;
          top: 5px; }
      .main-header-wrap .main-header .tools .account-options {
        padding-left: 25px;
        margin-right: 0px; }
        .main-header-wrap .main-header .tools .account-options .icon {
          font-size: 27px;
          top: 7px; }
    .main-header-wrap .main-header .mini-cart {
      cursor: auto;
      display: none;
      width: 328px;
      background: white;
      position: absolute;
      top: 35px;
      left: -243px;
      border: 1px solid #5c5555; }
      @media (max-width: 719px) {
        .main-header-wrap .main-header .mini-cart {
          display: none; } }
      .main-header-wrap .main-header .mini-cart.account-enabled {
        left: -157px; }
        .main-header-wrap .main-header .mini-cart.account-enabled .arrow {
          left: 205px; }
      .main-header-wrap .main-header .mini-cart.empty-cart .no-items {
        display: block; }
      .main-header-wrap .main-header .mini-cart.empty-cart .options {
        display: none; }
      .main-header-wrap .main-header .mini-cart .arrow, .main-header-wrap .main-header .mini-cart .arrow:before {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        top: -8px;
        left: 294px;
        margin-left: -7px; }
        .lt-ie9 .main-header-wrap .main-header .mini-cart .arrow, .lt-ie9 .main-header-wrap .main-header .mini-cart .arrow:before {
          display: none; }
      .main-header-wrap .main-header .mini-cart .arrow:before {
        content: '';
        display: block;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid #5c5555;
        z-index: -1;
        left: 50%;
        position: absolute;
        margin-left: -16px;
        top: -1px; }
      .main-header-wrap .main-header .mini-cart .no-items {
        display: none;
        text-align: center;
        margin: 30px 0 30px;
        font-family: "PT Serif", "Times New Roman", Times, serif;
        font-style: italic;
        font-size: 14px; }
      .main-header-wrap .main-header .mini-cart .item {
        width: 100%;
        border-bottom: 1px solid #4f4949; }
      .main-header-wrap .main-header .mini-cart .image-wrap, .main-header-wrap .main-header .mini-cart .details {
        float: left;
        margin: 20px; }
      .main-header-wrap .main-header .mini-cart .image-wrap {
        position: relative;
        width: 97px; }
        .main-header-wrap .main-header .mini-cart .image-wrap img {
          width: 100%; }
        .main-header-wrap .main-header .mini-cart .image-wrap a {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0; }
          .main-header-wrap .main-header .mini-cart .image-wrap a.overlay {
            box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
      .main-header-wrap .main-header .mini-cart .details {
        margin-left: 0px;
        width: 150px; }
      .main-header-wrap .main-header .mini-cart .brand {
        font-size: 10px;
        margin: 0px 0px 3px 0px; }
        .main-header-wrap .main-header .mini-cart .brand a {
          color: #362c2c; }
      .main-header-wrap .main-header .mini-cart .title {
        font-family: "PT Serif", "Times New Roman", Times, serif;
        font-size: 14px;
        margin: 0px 0px 7px 0px; }
        .main-header-wrap .main-header .mini-cart .title span {
          color: #cccaca; }
          .main-header-wrap .main-header .mini-cart .title span.quantity {
            margin-left: 4px; }
        .main-header-wrap .main-header .mini-cart .title a {
          color: #5d5b57; }
      .main-header-wrap .main-header .mini-cart .price {
        font-family: "PT Serif", "Times New Roman", Times, serif;
        font-size: 12px;
        color: #362c2c;
        margin: 0px 0px 6px 0px; }
      .main-header-wrap .main-header .mini-cart .variant,
      .main-header-wrap .main-header .mini-cart .property {
        font-family: "Raleway", sans-serif;
        font-size: 11px;
        color: #362c2c;
        margin: 0; }
      .main-header-wrap .main-header .mini-cart .property {
        margin-top: 2px; }
      .main-header-wrap .main-header .mini-cart .options {
        padding: 20px; }
        .main-header-wrap .main-header .mini-cart .options .action-button {
          width: 133px;
          text-align: center; }
        .main-header-wrap .main-header .mini-cart .options .view-cart {
          float: left; }
        .main-header-wrap .main-header .mini-cart .options .checkout {
          float: right; }

.main-header-slim .mini-cart-items-wrap {
  max-height: calc(100vh - 160px);
  overflow: auto; }

.search-wrap {
  display: none;
  width: 25%;
  z-index: 2500;
  background-color: white;
  border: 1px solid #5c5555;
  position: absolute;
  top: 0px;
  right: 14px;
  border-radius: 31px; }
  @media (max-width: 719px) {
    .search-wrap {
      display: none; } }
  .search-wrap form {
    display: block;
    font-size: 0px; }
  .search-wrap input {
    display: block;
    font-size: 12px;
    width: 100%;
    padding: 8px 38px 8px 12px;
    font-family: "Raleway", sans-serif;
    color: #aeaaaa; }
    .ie10 .search-wrap input {
      padding-top: 9px; }
  .search-wrap .icon-search {
    position: absolute;
    top: 50%;
    right: 12px;
    height: 24px;
    width: 15px;
    margin-top: -13px;
    font-family: 'icons';
    -webkit-font-smoothing: antialiased;
    font-size: 25px;
    line-height: 26px;
    color: #5c5555; }
  .header-centered .search-wrap.full {
    top: 15px;
    left: 15px; }
  .header-centered .header-social-links .search-wrap.full {
    right: 15px;
    left: auto; }
  .main-header-slim .search-wrap {
    top: 15px; }
  .header-centered.main-header-slim .search-wrap {
    top: 50%;
    right: 30px;
    left: auto;
    margin-top: -16px; }

.currency-switcher {
  font-family: "Raleway", sans-serif; }
  .currency-switcher .selected-currency {
    display: inline-block; }
  .currency-switcher .drop-arrow {
    font-family: 'icons';
    -webkit-font-smoothing: antialiased;
    position: relative;
    top: -1px;
    left: 0;
    line-height: 0px;
    margin-left: 4px; }
    .compact .currency-switcher .drop-arrow {
      display: inline-block;
      font-size: 14px; }
  .currency-switcher select[name=currencies] {
    color: black;
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: 2;
    width: 100%;
    height: 100%;
    -webkit-appearance: none; }
  .compact .currency-switcher {
    font-size: 13px;
    padding-top: 14px;
    font-weight: 600; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .main-header-wrap .main-header .search-wrap .icon {
    line-height: 24px; } }
.action-links .icon-search,
.action-links .icon-bag,
.action-links .icon-cart {
  width: 25px;
  height: 18px;
  vertical-align: bottom;
  margin: 0 10px; }

.compact .icon-bag,
.compact .icon-cart {
  width: 25px;
  height: 18px; }

.main-header-wrap .icon-search {
  width: 18px; }

@media screen and (max-width: 719px) {
  .header-mobile-stick .compact {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0; }
  .header-mobile-stick .action-links {
    display: none; }
  .header-mobile-stick + * {
    padding-top: 175px; } }
@media screen and (min-width: 720px) {
  .main-header-slim .action-links {
    display: none; } }
@media screen and (min-width: 720px) {
  .main-header-slim .main-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: white; } }
.main-header-slim .mega-nav {
  margin-top: -5px; }
.main-header-slim .main-header .header-minimal > * {
  display: table-cell;
  vertical-align: middle; }
.main-header-slim .main-header nav.full.multi-line:before {
  display: none; }
.main-header-slim .header-minimal {
  display: table;
  border-bottom: 1px solid #5c5555;
  width: 100%;
  table-layout: fixed;
  padding-left: 2em;
  padding-right: 2em;
  white-space: nowrap; }
  .main-header-slim .header-minimal .store-title {
    float: none;
    padding: 0;
    white-space: nowrap;
    width: 25%; }
    .main-header-slim .header-minimal .store-title img {
      max-width: 100%;
      padding-top: 5px;
      padding-bottom: 5px; }
  .main-header-slim .header-minimal nav.full {
    width: 70%;
    text-align: center; }
    .main-header-slim .header-minimal nav.full.bordered {
      border: 0; }
    .main-header-slim .header-minimal nav.full ul {
      white-space: normal;
      text-align: left; }
    .main-header-slim .header-minimal nav.full .nav-item .label:before {
      display: none; }
  .main-header-slim .header-minimal .tools {
    visibility: visible;
    float: none;
    text-align: right;
    width: 40%; }
    .main-header-slim .header-minimal .tools svg {
      width: 1.32em;
      height: 1.32em;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em; }
  .main-header-slim .header-minimal .search-wrap {
    margin-top: 0; }
  @media screen and (max-width: 1200px) {
    .main-header-slim .header-minimal .store-title {
      width: 100%;
      text-align: center;
      padding: 1em 2em; }
    .main-header-slim .header-minimal .tools,
    .main-header-slim .header-minimal nav.full {
      display: none; } }
.main-header-slim ~ .page-body-content {
  padding-top: 1px; }

.header-centered .main-header-slim .header-minimal-centered nav,
.header-centered .main-header-slim .header-minimal-centered .tools {
  width: 30%; }
.header-centered .main-header-slim .header-minimal-centered .store-title {
  width: 30%; }
.header-centered .main-header-slim .header-minimal-centered nav {
  width: 30%;
  padding: 0; }
.header-centered .main-header-slim .header-minimal-centered .tools .tools-left {
  float: none; }

.main-header-slim .mobile-dropdown {
  max-height: calc(100vh - 58px);
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0px;
  right: 0px; }

.header-centered .main-header-wrap {
  display: block !important; }

/* scss-combine:common/home-video */
.home-video-embed-wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out; }
  .home-video-embed-wrapper.opening {
    display: block; }
  .home-video-embed-wrapper.open {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.home-video-embed {
  width: 100%;
  margin: 0 auto;
  padding: 30px; }
  .home-video-embed.centered {
    position: absolute;
    top: 50%;
    padding: 0 30px; }
  .home-video-embed iframe {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.4s ease-out;
    -moz-transition: opacity 0.4s ease-out;
    transition: opacity 0.4s ease-out; }
  .home-video-embed .fluid-width-video-wrapper iframe {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.home-video .home-module-content {
  position: relative; }

.home-video-screenshot {
  position: relative; }
  .home-video-screenshot img,
  .home-video-screenshot svg {
    display: block;
    width: 100%; }

.home-video-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 640px;
  padding: 0 30px;
  color: white;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  @media (min-width: 720px) {
    .sidebar-enabled .home-video-content {
      position: relative;
      top: auto;
      left: auto;
      margin: 0 auto;
      -webkit-transform: translate(0, 0);
      -moz-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0); } }
  .text-color-dark .home-video-content {
    color: black; }
  .home-video-content h2 {
    margin: 30px 0 0;
    color: inherit;
    font-size: 40px; }
    @media (min-width: 720px) {
      .sidebar-enabled .home-video-content h2 {
        font-size: 24px; } }
  .home-video-content p {
    margin-top: 30px; }
  .home-video-content .button {
    background: none;
    border: 2px solid white;
    color: white; }
  .text-color-dark .home-video-content .button {
    border-color: black;
    color: black; }
  @media (min-width: 720px) {
    .sidebar-enabled .text-color-dark .home-video-content .button, .sidebar-enabled .text-color-light .home-video-content .button {
      border: none; } }

.home-video-play-button {
  cursor: pointer; }
  .text-color-dark .home-video-play-button {
    color: black; }
  .home-video-play-button svg {
    width: 55px;
    height: 55px;
    padding: 15px;
    vertical-align: middle;
    background-color: white;
    color: #362c2c;
    border-radius: 50%; }
  @media (min-width: 720px) {
    .sidebar-enabled .home-video-play-button {
      display: none; } }
  .home-video-play-button.mobile {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    @media (min-width: 720px) {
      .sidebar-enabled .home-video-play-button.mobile {
        display: block; } }

/* scss-combine:common/navigation */
.main-header nav {
  padding: 0 15px;
  border-top: 1px solid #dddddd; }
  .main-header nav .content-area {
    padding: 0; }
  .main-header nav.bordered {
    border-bottom: 1px solid #dddddd; }
  .main-header nav.full {
    display: block; }
    @media (max-device-width: 1024px), (max-width: 719px) {
      .main-header nav.full {
        display: none; } }
  .main-header nav.compact {
    display: none; }
    @media (max-device-width: 1024px), (max-width: 719px) {
      .main-header nav.compact {
        display: block; } }

.main-header-slim nav.compact {
  display: none; }
  @media screen and (max-width: 1200px) {
    .main-header-slim nav.compact {
      display: block; } }

.main-header nav.full {
  position: relative;
  z-index: 1000; }
  .header-centered .main-header-nonstick .main-header nav.full > ul {
    text-align: center; }
  .main-header nav.full.multi-line {
    text-align: center; }
    .main-header nav.full.multi-line::before {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      display: block;
      height: 1px;
      background-color: #5c5555;
      content: ""; }
  .main-header nav.full.compress .nav-item > .label {
    padding: 25px 15px;
    font-size: 11px; }
  .main-header nav.full.compress .nav-item.dropdown > a, .main-header nav.full.compress .nav-item.has-mega-nav > a {
    padding-right: 30px; }
    .main-header nav.full.compress .nav-item.dropdown > a .icon, .main-header nav.full.compress .nav-item.has-mega-nav > a .icon {
      right: 12px;
      line-height: 14px; }
  .main-header nav.full .nav-item {
    position: relative;
    display: inline-block;
    text-align: left; }
    .main-header nav.full .nav-item > .label {
      position: relative;
      display: block;
      padding: 25px;
      font-size: 12px;
      color: #b38d41; }
      .main-header nav.full .nav-item > .label::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 1px;
        background-color: #dddddd;
        content: ""; }
      .main-header nav.full .nav-item > .label:hover {
        color: #222222; }
    body:not(.header-centered) .main-header-nonstick .main-header nav.full .nav-item.first .label {
      padding-left: 0; }
    .main-header nav.full .nav-item.last a::before {
      display: none; }
    .main-header nav.full .nav-item.dropdown {
      position: relative; }
    .main-header nav.full .nav-item.dropdown > a, .main-header nav.full .nav-item.has-mega-nav > a {
      padding-right: 40px; }
    .main-header nav.full .nav-item.dropdown:hover, .main-header nav.full .nav-item.has-mega-nav.active {
      background-color: #4c4841; }
      .main-header nav.full .nav-item.dropdown:hover > .label, .main-header nav.full .nav-item.has-mega-nav.active > .label {
        color: white; }
        .main-header nav.full .nav-item.dropdown:hover > .label::before, .main-header nav.full .nav-item.has-mega-nav.active > .label::before {
          background-color: #66635d; }
        .main-header nav.full .nav-item.dropdown:hover > .label::after, .main-header nav.full .nav-item.has-mega-nav.active > .label::after {
          position: absolute;
          top: 0;
          left: -1px;
          width: 1px;
          height: 100%;
          background-color: #66635d;
          content: ""; }
          .main-header-slim .main-header nav.full .nav-item.dropdown:hover > .label::after, .main-header-slim .main-header nav.full .nav-item.has-mega-nav.active > .label::after {
            display: none; }
      .main-header nav.full .nav-item.dropdown:hover > .dropdown-wrap, .main-header nav.full .nav-item.has-mega-nav.active > .dropdown-wrap {
        display: block; }
    .main-header nav.full .nav-item .icon {
      position: absolute;
      top: 25px;
      right: 22px;
      width: 12px;
      height: 12px;
      font-family: "icons";
      font-size: 16px;
      line-height: 15px; }
      .ie10 .main-header nav.full .nav-item .icon {
        top: 27px; }
      .lt-ie9 .main-header nav.full .nav-item .icon {
        top: 27px; }
    .main-header nav.full .nav-item .dropdown-wrap {
      position: absolute;
      top: 100%;
      left: -1px;
      z-index: 1000;
      display: none;
      width: 198px;
      background-color: #4c4841;
      border: 1px solid #66635d; }
      .main-header-slim .main-header nav.full .nav-item .dropdown-wrap {
        left: 0; }
      .main-header nav.full .nav-item .dropdown-wrap.child.right {
        top: 0;
        right: -198px;
        left: auto; }
      .main-header nav.full .nav-item .dropdown-wrap.child.left {
        top: 0;
        left: -198px; }
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item {
        position: relative; }
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > a .label, .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > a .icon {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100); }
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item:hover > .dropdown-wrap {
          display: block; }
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item .icon {
          top: 26px;
          right: 16px;
          font-size: 18px; }
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a {
        display: block;
        padding: 23px 42px 23px 26px;
        font-family: "PT Serif", "Times New Roman", Times, serif;
        font-size: 14px;
        border-top: 1px solid #dddddd; }
        .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .label, .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .icon {
          opacity: 0.7;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
          filter: alpha(opacity=70);
          color: white;
          background-color: #4c4841; }
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item.first > a {
        border-top: 0; }
      .main-header nav.full .nav-item .dropdown-wrap .dropdown-item.first > .dropdown-wrap {
        top: -1px; }

nav.compact {
  position: relative; }
  nav.compact ul {
    font-size: 0; }
  nav.compact .nav-item {
    position: relative;
    display: inline-block;
    width: 33.33333%;
    height: 55px;
    font-family: "icons";
    -webkit-font-smoothing: antialiased;
    text-align: center; }
    nav.compact .nav-item.dropdown .icon {
      margin-top: 8px;
      font-size: 27px; }
    nav.compact .nav-item.account .icon {
      margin-top: 8px;
      font-size: 27px; }
    nav.compact .nav-item.cart .icon {
      margin-top: -5px;
      font-size: 41px; }
    nav.compact .nav-item.search .icon {
      margin-top: 8px;
      font-size: 27px; }
    nav.compact .nav-item.cart .count {
      position: absolute;
      top: 14px;
      left: 50%;
      display: block;
      padding: 3px 3px 0;
      margin-left: 4px;
      line-height: 10px;
      background-color: #b38d41;
      border: 1px solid white;
      border-radius: 12px; }
      nav.compact .nav-item.cart .count span {
        position: relative;
        top: -2px;
        padding: 0;
        margin: 0;
        font-family: "Helvetica Neue";
        font-size: 10px;
        color: white; }
    nav.compact .nav-item > a,
    nav.compact .nav-item > div {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      color: #b38d41;
      cursor: pointer; }
      nav.compact .nav-item > a span,
      nav.compact .nav-item > div span {
        display: block;
        margin-top: 15px;
        text-align: center; }
      nav.compact .nav-item > a::before,
      nav.compact .nav-item > div::before {
        position: absolute;
        top: 11px;
        right: 0;
        bottom: 11px;
        width: 1px;
        background-color: #dddddd;
        content: ""; }
    nav.compact .nav-item.last a::before, nav.compact .nav-item.last div::before {
      display: none; }
    nav.compact .nav-item.active {
      background-color: #4c4841; }
      nav.compact .nav-item.active > a, nav.compact .nav-item.active > div {
        color: white; }
        nav.compact .nav-item.active > a::before, nav.compact .nav-item.active > div::before {
          background-color: white; }
          @media (max-device-width: 1024px), (max-width: 719px) {
            nav.compact .nav-item.active > a::before, nav.compact .nav-item.active > div::before {
              display: none; } }
      nav.compact .nav-item.active .arrow {
        background-color: white; }
    nav.compact .nav-item .dropdown-wrap {
      display: none; }
      nav.compact .nav-item .dropdown-wrap.top {
        position: absolute;
        top: 100%;
        left: 0; }
  nav.compact.account-enabled .nav-item, nav.compact.currency-enabled .nav-item {
    width: 25%; }
  nav.compact.account-enabled.currency-enabled .nav-item {
    width: 20%; }
  nav.compact .search-outer-wrap {
    position: absolute;
    top: -5px;
    right: 0;
    bottom: -5px;
    left: 0;
    display: none;
    background: transparent; }
    nav.compact .search-outer-wrap .search-wrap {
      top: 50%;
      right: 8%;
      left: 8%;
      display: block;
      width: auto;
      height: 36px; }

.mega-nav {
  position: absolute;
  top: -9999px;
  right: 0;
  left: 0;
  z-index: 1500;
  margin-top: 1px;
  overflow: hidden;
  background-color: #4c4841;
  box-shadow: 0 1px 0 0 rgba(76, 72, 65, 0.2); }
  .mega-nav .mega-nav-wrap {
    position: relative;
    padding: 0 15px; }
  .mega-nav .back {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    position: absolute;
    top: 47px;
    left: 50%;
    z-index: 5000;
    display: none;
    width: 0;
    height: 0;
    margin-left: -2px;
    border-right: 5px solid transparent;
    border-bottom: 10px solid white;
    border-left: 5px solid transparent; }
    .mega-nav .back:hover {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
  .mega-nav .list {
    display: inline-block;
    padding-left: 30px;
    margin-top: 34px;
    margin-bottom: 34px;
    vertical-align: top;
    background-color: #4c4841; }
    .mega-nav .list.first {
      padding-left: 0; }
    .mega-nav .list.one-col {
      width: 100%; }
      .mega-nav .list.one-col .list-item {
        width: 150px; }
    .mega-nav .list.two-col {
      width: 50%; }
    .mega-nav .list.three-col {
      width: 33.33333%; }
    .mega-nav .list .label {
      width: 100%;
      padding: 10px 0 24px;
      margin: 0 0 22px;
      font-size: 14px;
      color: white;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
      .mega-nav .list .label a {
        color: white; }
    .mega-nav .list .list-item {
      display: inline-block;
      width: 50%;
      padding-right: 10px;
      margin: 0 -4px 14px 0;
      vertical-align: top; }
      .mega-nav .list .list-item.one-column {
        width: 100%; }
      .mega-nav .list .list-item a {
        opacity: 0.7;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
        filter: alpha(opacity=70);
        font-size: 14px;
        color: white;
        background-color: #4c4841; }
        .mega-nav .list .list-item a:hover {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100); }
      .mega-nav .list .list-item a.show-more {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100); }
      .mega-nav .list .list-item .more-icon {
        position: relative;
        top: -1px;
        margin-left: 6px;
        font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
        font-weight: 100; }
  .mega-nav .expanded-list,
  .mega-nav .category-list {
    position: relative;
    display: none; }
    .mega-nav .expanded-list .list,
    .mega-nav .category-list .list {
      display: none;
      width: 100%;
      margin-left: 0; }
      .mega-nav .expanded-list .list.active,
      .mega-nav .category-list .list.active {
        display: block; }
    .mega-nav .expanded-list .list-item,
    .mega-nav .category-list .list-item {
      width: 150px; }

.mobile-dropdown {
  position: relative;
  z-index: 5000;
  display: none;
  margin-top: -2px;
  background-color: #4c4841; }
  .mobile-dropdown .list.primary {
    background-color: #4c4841; }
    .mobile-dropdown .list.primary > .first {
      padding-top: 10px;
      border-top: 0; }
  .mobile-dropdown .list.secondary {
    display: none;
    background-color: #54514a; }
    .mobile-dropdown .list.secondary .list-item a {
      background-color: #54514a; }
  .mobile-dropdown .list.tertiary {
    display: none;
    background-color: #5d5a54; }
    .mobile-dropdown .list.tertiary .list-item a {
      background-color: #5d5a54; }
  .mobile-dropdown .list.quaternary {
    display: none;
    background-color: #66635d; }
    .mobile-dropdown .list.quaternary .list-item a {
      background-color: #66635d; }
  .mobile-dropdown .list .list-item {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.05); }
    .mobile-dropdown .list .list-item a {
      opacity: 0.7;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      filter: alpha(opacity=70);
      position: relative;
      display: block;
      width: 84%;
      min-width: 290px;
      padding: 19px 15px;
      margin: 0 auto;
      font-size: 12px;
      color: white; }
    .mobile-dropdown .list .list-item .icon {
      opacity: 0.7;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
      filter: alpha(opacity=70);
      position: absolute;
      top: 21px;
      right: 0;
      height: 12px;
      font-size: 25px;
      line-height: 21px;
      text-align: center;
      transform: rotate(45deg); }
    .mobile-dropdown .list .list-item .icon::after {
      display: block;
      width: 12px;
      height: 12px;
      overflow: hidden;
      font-size: 25px;
      line-height: 8px;
      color: white;
      text-align: center;
      content: "\00d7"; }
    .mobile-dropdown .list .list-item.expanded > a .icon {
      transform: rotate(0deg); }

.compact .mobile-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0; }

.search-wrap-contained {
  position: relative; }
  .main-header-slim .search-wrap-contained {
    width: 100%;
    max-width: 100%; }

/* scss-combine:common/normalize-bottom */
.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0; }

.ir br {
  display: none; }

.hidden {
  display: none !important;
  visibility: hidden; }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }

  a, a:visited {
    text-decoration: underline; }

  a[href]:after {
    content: " (" attr(href) ")"; }

  abbr[title]:after {
    content: " (" attr(title) ")"; }

  .ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after {
    content: ""; }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group; }

  tr, img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important; }

  @page {
    margin: 0.5cm; }

  p, h2, h3 {
    orphans: 3;
    widows: 3; }

  h2, h3 {
    page-break-after: avoid; } }
/* scss-combine:common/normalize-top */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

[hidden] {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, button, input, select, textarea {
  font-family: sans-serif;
  color: #222; }

body {
  margin: 0;
  font-size: 1em;
  line-height: 1.4; }

a {
  text-decoration: none; }

a:focus {
  outline: none; }

a:hover, a:active {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

blockquote {
  margin: 1em 40px; }

dfn {
  font-style: italic; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

ins {
  background: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold; }

pre, code, kbd, samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: none; }

q:before, q:after {
  content: "";
  content: none; }

small {
  font-size: 85%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0; }

ul, ol {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none; }

dd {
  margin: 0 0 0 40px; }

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

label {
  cursor: pointer; }

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
  white-space: normal; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button, input {
  line-height: normal; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible; }

button[disabled], input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

input:invalid, textarea:invalid {
  background-color: #f0dddd; }

input {
  background: none;
  outline: none;
  border: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td {
  vertical-align: top; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

/* scss-combine:common/rte */
.rte {
  font-size: 14px; }
  .rte p, .rte blockquote, .rte h1, .rte h2, .rte ul, .rte ol {
    margin: 0 0 20px; }
  .rte p, .rte blockquote, .rte li, .rte a {
    font-size: 14px;
    line-height: 22px; }
  .rte > table:first-child {
    margin-top: 0px; }
  .rte > p:first-child .image-wrap {
    margin-top: 6px; }
  .rte ul, .rte ol {
    padding-left: 20px; }
  .rte ul {
    list-style-type: disc; }
  .rte ol {
    list-style-type: decimal; }
  .rte li {
    margin: 8px 0; }
  .rte blockquote {
    max-width: 70%;
    font-style: italic;
    color: #aeaaaa;
    padding-left: 20px;
    margin-left: 0;
    border-left: 2px solid #4f4949; }
  .rte img {
    max-width: 100%; }
  .rte iframe, .rte object, .rte embed {
    border: 0;
    outline: 0; }
  .rte a {
    color: #5d5b57; }
    .rte a:hover {
      color: #060606; }
  .rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6 {
    color: #b38d41;
    font-weight: 7000; }
  .rte h1 {
    font-size: 28px; }
  .rte h2 {
    font-size: 21px; }
  .rte h3 {
    font-size: 18px;
    margin: 0 0 18px; }
  .rte h4 {
    font-size: 16px;
    margin: 0 0 16px; }
  .rte h5 {
    font-size: 14px;
    margin: 0 0 14px; }
  .rte h6 {
    font-size: 12px;
    margin: 0 0 12px; }
  .rte hr {
    width: 100%;
    height: 1px;
    margin: 40px 0;
    background-color: #4f4949;
    outline: none;
    border: none; }
    @media (max-width: 719px) {
      .rte hr {
        margin: 20px 0; } }
  .rte .caption {
    font-style: italic;
    font-size: 12px;
    color: #aeaaaa;
    margin-top: -10px; }
  .rte .image-wrap {
    max-width: 100%;
    position: relative;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    display: inline-block; }
    .rte .image-wrap img,
    .rte .image-wrap svg {
      display: block;
      position: relative;
      z-index: -2; }
  .rte table {
    margin: 30px 0;
    width: 100%;
    border: 1px solid #5c5555; }
    .rte table td, .rte table th {
      border: 1px solid #4f4949;
      padding: 23px 25px 22px; }
    .rte table thead td, .rte table thead th {
      background-color: #d6d4d4; }
  .rte .tabs {
    text-align: left;
    display: block;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid #5c5555;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap; }
    .rte .tabs li {
      width: auto;
      height: 40px;
      padding: 0;
      margin: 0;
      display: inline-block;
      vertical-align: top; }
      .rte .tabs li a {
        display: block;
        height: 41px;
        font-family: "Raleway", sans-serif;
        font-size: 14px;
        line-height: 42px;
        padding: 0 16px;
        color: #5d5b57;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent; }
        .rte .tabs li a:hover {
          color: #b38d41; }
      .rte .tabs li.active a {
        color: #362c2c;
        border-top: 1px solid #5c5555;
        border-left: 1px solid #5c5555;
        border-right: 1px solid #5c5555;
        background-color: white;
        height: 42px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; }
  .rte .tabs-content {
    text-align: left;
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden; }
    .rte .tabs-content > li {
      display: none;
      margin: 30px 0 20px; }
      .rte .tabs-content > li.active {
        display: block; }

.column-title {
  display: none; }

table.mobile-layout {
  border-bottom: none; }
  table.mobile-layout .column-title {
    font-weight: 700;
    width: 114px;
    padding-right: 23px;
    display: inline-block; }
  table.mobile-layout thead {
    display: none; }
  table.mobile-layout tr td {
    width: 100%;
    display: block;
    text-align: left;
    border-top: 1px solid #5c5555 !important;
    border-bottom: none;
    border-left: none;
    border-right: none;
    /* < IE 10 fix */
    float: left;
    clear: left; }
    table.mobile-layout tr td:first-child {
      border-top: none !important; }
    table.mobile-layout tr td:last-child {
      border-bottom: 1px solid #5c5555 !important; }
  table.mobile-layout tr:nth-child(even) td {
    background: #d6d4d4; }

/* scss-combine:common/social-links */
.social-links {
  margin-bottom: -8px; }
  .main-header .social-links {
    margin-top: 20px;
    margin-bottom: -20px; }
    .main-header .social-links svg {
      width: 20px;
      height: 20px;
      color: #b38d41; }
  .header-centered .header-social-links .social-links {
    display: inline-block;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
    vertical-align: middle; }

/* scss-combine:snippets/blog-sidebar */
.sidebar {
  float: right;
  width: 25%;
  padding-left: 3%; }
  @media (max-width: 719px) {
    .sidebar {
      width: 100%;
      margin-top: 45px;
      margin-bottom: 10px;
      padding-left: 0px; } }
  .sidebar .module {
    border-top: 1px solid #4f4949;
    padding: 25px 0; }
    .sidebar .module.first {
      padding-top: 0;
      border-top: none; }
    .sidebar .module > label {
      display: block;
      margin-bottom: 22px;
      font-size: 14px;
      color: #b38d41;
      cursor: auto; }
  .sidebar .recent-posts .post {
    margin: 14px 0; }
    .sidebar .recent-posts .post.first {
      margin-top: 0px; }
    .sidebar .recent-posts .post.last {
      margin-bottom: 0px; }
  .sidebar .recent-posts .title {
    font-size: 14px;
    color: #b38d41;
    margin-bottom: 6px;
    display: inline-block; }
    .sidebar .recent-posts .title:hover {
      color: #5d5b57; }
  .sidebar .recent-posts .date {
    display: block;
    font-size: 12px;
    color: #aeaaaa; }
  .sidebar .tags-list .tag {
    margin: 12px 0; }
    .sidebar .tags-list .tag a {
      font-size: 14px;
      color: #b38d41; }
      .sidebar .tags-list .tag a:hover {
        color: #5d5b57; }
    .sidebar .tags-list .tag .count {
      font-size: 12px;
      color: #aeaaaa;
      margin-left: 5px; }
    .sidebar .tags-list .tag:hover .count {
      color: #9d9999; }
  .sidebar .twitter .tweets {
    margin-bottom: 20px; }
  .sidebar .twitter .tweet {
    margin-top: 22px; }
    .sidebar .twitter .tweet:first-child {
      margin-top: 0px; }
  .sidebar .twitter .text {
    font-style: italic;
    font-size: 14px;
    line-height: 22px;
    margin: 0; }
    .sidebar .twitter .text a {
      color: #5d5b57; }
  .sidebar .twitter .date {
    font-size: 12px;
    color: #aeaaaa;
    margin-top: 14px;
    display: inline-block; }
  .sidebar .twitter .follow-us {
    font-size: 14px; }

/* scss-combine:snippets/breadcrumbs */
.breadcrumb-navigation {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  padding: 45px 90px 5px 0px; }
  @media (max-width: 719px) {
    .breadcrumb-navigation {
      display: none; } }
  .breadcrumb-navigation a {
    color: #5d5b57; }
  .breadcrumb-navigation a:hover {
    color: #060606; }
  .template-collection .breadcrumb-navigation {
    padding: 0; }
  .breadcrumb-navigation .sep {
    padding-left: 4px;
    padding-right: 4px; }

/* scss-combine:snippets/collections */
.template-list-collections .collections {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: -30px; }
  @media (max-width: 719px) {
    .template-list-collections .collections {
      padding-top: 10px;
      margin-left: 0; } }
.template-list-collections .collection-wrap {
  margin-bottom: 25px; }
.template-list-collections .rte {
  margin-top: 20px; }
  @media (max-width: 719px) {
    .template-list-collections .rte {
      display: none; } }

@media (max-width: 719px) {
  .collections, .featured-collections {
    margin-left: 0; } }
.collections .collection-wrap, .featured-collections .collection-wrap {
  display: inline-block;
  vertical-align: top;
  width: 33.3333%;
  padding-left: 30px; }
  @media (max-width: 719px) {
    .collections .collection-wrap, .featured-collections .collection-wrap {
      width: 60%;
      min-width: 290px;
      display: block;
      margin: 0 auto 35px;
      padding: 0; } }
.collections .collection, .featured-collections .collection {
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 100%;
  padding-bottom: 76.66666667%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat; }
  .collections .collection:hover .label, .featured-collections .collection:hover .label {
    visibility: hidden; }
  .collections .collection:hover .browse, .featured-collections .collection:hover .browse {
    visibility: visible; }
  .collections .collection:hover a, .featured-collections .collection:hover a {
    background: url("//isabellaadams.com/cdn/shop/t/8/assets/ie-product-overlay-bg.png?v=130413172460849243661579199170") repeat 50% 50%;
    background: rgba(0, 0, 0, 0.5); }
  @media (max-width: 719px) {
    .collections .collection, .featured-collections .collection {
      height: 230px; }
      .collections .collection:hover .browse, .featured-collections .collection:hover .browse {
        visibility: hidden; }
      .collections .collection:hover .label, .featured-collections .collection:hover .label {
        visibility: visible; }
      .collections .collection:hover a, .featured-collections .collection:hover a {
        background: transparent !important; } }
  .collections .collection .label, .collections .collection .browse, .featured-collections .collection .label, .featured-collections .collection .browse {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 1000;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33);
    margin: 0;
    padding: 0 30px; }
  .collections .collection .label, .featured-collections .collection .label {
    font-size: 21px;
    font-family: "PT Serif", "Times New Roman", Times, serif; }
    .collections .collection .label.preload, .featured-collections .collection .label.preload {
      visibility: hidden; }
  .collections .collection .browse, .featured-collections .collection .browse {
    visibility: hidden;
    font-size: 14px;
    line-height: 30px; }
  .collections .collection a, .featured-collections .collection a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }

.collection-title-header {
  background-size: cover;
  padding-top: 0; }
  .collection-title-header .collection-title {
    text-align: center; }
  .collection-title-header .collection-image {
    width: 100%;
    opacity: 0; }
  .collection-title-header.page-title {
    min-height: 200px;
    overflow: hidden; }

.collection-header .breadcrumb-navigation {
  text-align: center; }

/* scss-combine:snippets/home-pictureblock */
.picture-block {
  position: relative;
  background-color: #f6f6f6; }
  .picture-block .picture-block-wrapper {
    position: relative; }
  .picture-block + .picture-block.home-section {
    margin-top: 0; }

.picture-block-image {
  background-size: cover;
  background-position: center center;
  height: 100%; }
  .picture-block-image img,
  .picture-block-image svg {
    width: 100%;
    opacity: 0; }

.picture-block + .content-area .section-border {
  border-top: 0; }

@media screen and (min-width: 700px) {
  .picture-block-right .picture-block-wrapper {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0; }
  .picture-block-right .picture-block-image {
    float: right;
    width: 50%; }

  .picture-block-left .picture-block-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%; }
  .picture-block-left .picture-block-image {
    float: left;
    width: 50%; } }
@media screen and (max-width: 700px) {
  .picture-block-wrapper .centering-wrapper {
    display: relative;
    position: static; }

  .picture-block-content {
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px; } }
.picture-block-content {
  max-width: 60%;
  text-align: center; }

.picture-block-heading {
  margin-bottom: 30px;
  color: #b38d41;
  font-family: "PT Serif", "Times New Roman", Times, serif;
  font-size: 60px;
  line-height: 62px;
  font-weight: 700; }
  @media screen and (max-width: 800px) {
    .picture-block-heading {
      font-size: 45px;
      line-height: 46px; } }
  @media screen and (max-width: 500px) {
    .picture-block-heading {
      font-size: 32px;
      line-height: 32px; } }

.picture-block-body {
  color: #aeaaaa; }

@media screen and (max-width: 700px) {
  .picture-block-image {
    width: 100%; } }
.picture-block-empty {
  background-color: #f6f6f6;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; }
  .picture-block-empty p {
    max-width: 75%; }

/* scss-combine:snippets/pagination */
.pagination {
  clear: both;
  padding-bottom: 10px;
  text-align: center; }
  .pagination > li {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    border-left: 1px solid #4f4949;
    padding: 3px 0 3px 8px;
    margin-left: 8px;
    display: inline-block; }
    .pagination > li:first-child {
      border-left: none;
      padding-left: 0;
      margin-left: 8px; }
    .pagination > li.previous a, .pagination > li.next a {
      color: #5d5b57; }
      .pagination > li.previous a:hover, .pagination > li.next a:hover {
        color: #060606; }
    .pagination > li.position {
      color: #362c2c; }
  .pagination.jump-to-page li.previous {
    padding-right: 8px;
    border-right: 1px solid #4f4949; }
  .pagination.jump-to-page li.jump-to-link {
    border: none;
    margin-left: 0; }

/* scss-combine:snippets/product-item */
.product-list {
  text-align: left;
  margin-left: -30px;
  font-size: 0; }
  @media (max-width: 719px) {
    .product-list {
      margin-left: 0; } }

.product {
  width: 25%;
  padding-left: 30px;
  text-align: left;
  display: inline-block;
  vertical-align: top; }
  @media (max-width: 719px) {
    .product {
      display: block;
      width: 60% !important;
      min-width: 290px !important;
      margin: 0 auto 35px;
      padding-left: 0px;
      text-align: center; }
      .product.last {
        margin-bottom: 0px; } }
  .product figure {
    position: relative;
    background-position: center center;
    background-size: cover; }
    .product-grid-default .product figure {
      background-image: none !important; }
    .product-grid-square .product figure {
      width: 100%;
      height: 0;
      padding-bottom: 100%; }
    .product-grid-tall .product figure {
      width: 100%;
      height: 0;
      padding-bottom: 125%; }
    .product-grid-taller .product figure {
      width: 100%;
      height: 0;
      padding-bottom: 150%; }
    .product-grid-wide .product figure {
      width: 100%;
      height: 0;
      padding-bottom: 66.66667%; }
    @media (max-width: 719px) {
      .product-grid-square .product figure, .product-grid-tall .product figure, .product-grid-taller .product figure, .product-grid-wide .product figure {
        height: auto;
        padding-bottom: 0;
        background-image: none !important; } }
    .product figure > img,
    .product figure > svg {
      width: 100%;
      vertical-align: top;
      display: none; }
      .product-grid-default .product figure > img, .product-grid-default
      .product figure > svg {
        display: block; }
      @media (max-width: 719px) {
        .product figure > img,
        .product figure > svg {
          display: block; } }
    .product figure > a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2000; }
      .product figure > a.with-border {
        box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
        .lt-ie9 .product figure > a.with-border {
          border: 1px solid #5c5555; }
  @media (max-width: 719px) {
    .product .with-border + .overlay {
      box-shadow: inset 0px 0px 0px 1px white; } }
  .product .overlay {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3001;
    background: url("//isabellaadams.com/cdn/shop/t/8/assets/ie-product-overlay-bg.png?v=130413172460849243661579199170") repeat 50% 50%;
    background: rgba(93, 91, 87, 0.7);
    cursor: pointer; }
    @media (max-width: 719px) {
      .product .overlay {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=100);
        position: relative;
        background: none;
        cursor: default;
        box-shadow: inset 0px 0px 0px 1px white; } }
    .product .overlay:hover {
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
      .product .overlay:hover ~ .centering-wrapper,
      .product .overlay:hover ~ .badges-wrapper {
        display: none; }
    .product .overlay .label {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 14px;
      line-height: 30px;
      padding: 0 15px;
      color: #ffffff; }
      @media (max-width: 719px) {
        .product .overlay .label {
          color: #b38d41; } }
  .product .quick-shop-content {
    display: none; }
  @media (max-width: 719px) {
    .product .quickshop-trigger {
      display: none; } }

.badge {
  display: inline-block;
  margin-right: 6px;
  padding: 7px 13px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px; }
  @media screen and (min-width: 1001px) and (max-width: 1250px) {
    .badge {
      font-size: 10px;
      padding: 5px 10px; } }
  @media screen and (min-width: 719px) and (max-width: 1500px) {
    .product-list-withsidebar .badge {
      font-size: 10px;
      padding: 5px 10px; } }

.badges-wrapper {
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 20px; }

.badge-sale {
  background-color: #5d5b57; }

.badge-new {
  background-color: #2c2c2c; }

.badge-soldout {
  background-color: silver; }

.product .brand, .quick-shop-content .brand, #product-area .brand {
  font-size: 12px;
  margin: 25px 0 0; }
  @media (max-width: 719px) {
    .product .brand, .quick-shop-content .brand, #product-area .brand {
      margin-top: 30px; } }
  .product .brand a, .quick-shop-content .brand a, #product-area .brand a {
    color: #aeaaaa; }
.product .title, .quick-shop-content .title, #product-area .title {
  font-size: 16px;
  font-weight: 700;
  color: #b38d41;
  font-family: "PT Serif", "Times New Roman", Times, serif;
  margin: 10px 0 0; }
  .product .title a, .quick-shop-content .title a, #product-area .title a {
    color: #b38d41; }
    .product .title a:hover, .quick-shop-content .title a:hover, #product-area .title a:hover {
      color: #5d5b57; }
.product .price, .quick-shop-content .price, #product-area .price {
  font-size: 14px;
  color: #362c2c;
  margin: 13px 0 0; }
  .product .price .label, .quick-shop-content .price .label, #product-area .price .label {
    color: #b38d41;
    margin-right: 5px; }
  .product .price .original, .quick-shop-content .price .original, #product-area .price .original {
    text-decoration: line-through;
    margin-right: 2px; }

.no-touch .product-card-alt .product-card-details-overlay {
  max-width: calc(100% - 30px);
  max-height: 100%;
  overflow: hidden; }
.no-touch .product-card-alt .product-card-details {
  display: none; }
  @media (max-width: 719px) {
    .no-touch .product-card-alt .product-card-details {
      display: block;
      margin-top: 25px; } }
.no-touch .product-card-alt .brand {
  margin-top: 0; }

.product-card-details-overlay {
  color: white;
  text-align: center; }
  @media (max-width: 719px) {
    .product-card-details-overlay {
      display: none; } }
  .product-card-details-overlay .title {
    font-size: 22px; }
  .product-card-details-overlay .title,
  .product-card-details-overlay .price,
  .product-card-details-overlay a:hover,
  .product-card-details-overlay a {
    color: white; }
    @media (max-width: 719px) {
      .product-card-details-overlay .title,
      .product-card-details-overlay .price,
      .product-card-details-overlay a:hover,
      .product-card-details-overlay a {
        color: #b38d41; } }

/* scss-combine:snippets/product-quick-shop */
/*
The quick shop product area derives almost all
of it's styles from the product page. Styles can
be found in product.scss
*/
.quick-shop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("//isabellaadams.com/cdn/shop/t/8/assets/ie-product-overlay-bg.png?v=130413172460849243661579199170") repeat 50% 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  overflow: auto;
  -moz-opacity: 0;
  opacity: 0; }

.quick-shop-modal {
  width: 72%;
  min-width: 690px;
  max-width: 1028px;
  padding: 15px;
  margin: 0 auto; }

.quick-shop-content {
  background: white;
  padding: 42px 30px 65px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2); }
  .quick-shop-content .product-details-wrapper .header {
    padding-bottom: 26px !important;
    position: relative; }
    .quick-shop-content .product-details-wrapper .header .close-modal {
      font-family: 'icons';
      font-size: 3em;
      -webkit-font-smoothing: antialiased;
      line-height: 0px;
      position: absolute;
      top: -28px;
      right: -30px;
      color: #ccc;
      cursor: pointer; }
    .quick-shop-content .product-details-wrapper .header .title {
      font-size: 28px !important;
      margin: 8px 0 15px !important; }
  .quick-shop-content .product-details-wrapper .rte .image-wrap img {
    z-index: 1; }
  .quick-shop-content .product-details-wrapper .go-to-product span {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: relative;
    top: -1px; }
  .quick-shop-content .product-details-wrapper .error-wrap {
    font-size: 14px;
    font-style: italic;
    color: #5d5b57; }

/* scss-combine:snippets/shipping-calculator */
#shipping-calculator {
  padding-top: 40px;
  border-top: 1px solid #5c5555; }
  #shipping-calculator .wrapper-setup {
    width: 40%;
    float: left; }
    @media (max-width: 719px) {
      #shipping-calculator .wrapper-setup {
        width: 100%; } }
    #shipping-calculator .wrapper-setup p {
      margin: 25px 0; }
      #shipping-calculator .wrapper-setup p:first-child {
        margin-top: 0; }
    #shipping-calculator .wrapper-setup label, #shipping-calculator .wrapper-setup select {
      display: inline-block; }
    #shipping-calculator .wrapper-setup label {
      width: 50%;
      font-size: 14px;
      text-align: right;
      padding-right: 30px;
      cursor: auto; }
      @media (max-width: 719px) {
        #shipping-calculator .wrapper-setup label {
          width: 100%;
          text-align: left;
          display: block;
          padding-right: 0px;
          margin-bottom: 15px; } }
    #shipping-calculator .wrapper-setup #address_country, #shipping-calculator .wrapper-setup #address_province, #shipping-calculator .wrapper-setup #address_zip {
      width: 50%; }
      @media (max-width: 719px) {
        #shipping-calculator .wrapper-setup #address_country, #shipping-calculator .wrapper-setup #address_province, #shipping-calculator .wrapper-setup #address_zip {
          width: 100%; } }
    #shipping-calculator .wrapper-setup .pxuSexyDropWrapper {
      width: 50% !important; }
      @media (max-width: 719px) {
        #shipping-calculator .wrapper-setup .pxuSexyDropWrapper {
          width: 100% !important; } }
      #shipping-calculator .wrapper-setup .pxuSexyDropWrapper #address_country, #shipping-calculator .wrapper-setup .pxuSexyDropWrapper #address_province {
        width: 100%; }
    #shipping-calculator .wrapper-setup .input-wrap {
      width: 50%;
      float: right; }
      @media (max-width: 719px) {
        #shipping-calculator .wrapper-setup .input-wrap {
          width: 100%;
          margin-top: 10px; }
          #shipping-calculator .wrapper-setup .input-wrap .action-button {
            width: 100%; } }
  #shipping-calculator .wrapper-response {
    width: 50%;
    float: right;
    font-size: 14px; }
    @media (max-width: 719px) {
      #shipping-calculator .wrapper-response {
        width: 100%;
        margin-top: 10px; } }
    #shipping-calculator .wrapper-response .message {
      font-style: italic;
      margin: 0 0 25px; }
    #shipping-calculator .wrapper-response .shipping-rates {
      list-style: disc;
      padding-left: 20px;
      color: #aeaaaa; }

/* scss-combine:snippets/shoppad-apps */
/*
* * * * * * * * * * * * * * * * * * * * * * * * * * *
ShopPad App: Tracktor
https://apps.shopify.com/tracktor-2
* * * * * * * * * * * * * * * * * * * * * * * * * * */
#tracktorContainer {
  position: relative;
  margin: 0 auto !important;
  padding: 0 15px;
  max-width: 1030px;
  width: 84% !important; }
  #tracktorContainer .signedby {
    margin-bottom: 10px; }

#tracktorOrderDetails h1 {
  font-family: "PT Serif", "Times New Roman", Times, serif;
  color: #b38d41; }
#tracktorOrderDetails p {
  color: #362c2c; }

#tracktor.themeDark #tracktorOrderStatus div,
#tracktor.themeLight #tracktorOrderStatus div {
  background-color: white !important;
  box-shadow: 0 0 0 1px #5c5555 !important; }

#tracktorProgress dt {
  color: #b38d41; }
#tracktorProgress dd {
  color: #362c2c; }

#tracktorLoader span,
#tracktorOrderError p {
  color: #362c2c; }

#tracktorOrderForm h1 {
  color: #b38d41;
  font-family: "PT Serif", "Times New Roman", Times, serif; }
#tracktorOrderForm label {
  position: relative;
  font-size: 14px;
  color: #362c2c;
  margin-bottom: 5px;
  width: 100%;
  display: inline-block; }
#tracktorOrderForm input {
  box-sizing: border-box;
  width: 100%;
  color: #aeaaaa;
  font-size: 12px;
  padding: 8px 10px;
  font-family: "Raleway", sans-serif;
  background-color: white;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #5c5555;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none; }
#tracktorOrderForm #tracktorTrack {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #f9f4ee;
  background-color: #5d5b57;
  padding: 9px 14px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  border: 0; }
  #tracktorOrderForm #tracktorTrack:hover {
    background-color: #060606;
    color: #f9f4ee; }

#tracktorOrderStatus h1 {
  color: #b38d41; }

@media screen and (max-width: 797px) {
  #tracktor.themeDark #tracktorOrderStatus div,
  #tracktor.themeLight #tracktorOrderStatus div {
    height: auto !important;
    background-color: rgba(0, 0, 0, 0) !important;
    box-shadow: none !important; } }
/* * * * * * * * * * * * * * * * * * * * * * * * * * * *
ShopPad App: Bouncer
https://apps.shopify.com/bouncer
* * * * * * * * * * * * * * * * * * * * * * * * * * */
#bouncer_modal_heading {
  font-family: "PT Serif", "Times New Roman", Times, serif; }

#bouncer_modal_subheading,
#bouncer_modal_exit {
  font-family: "PT Serif", "Times New Roman", Times, serif; }

#bouncer_modal_datepicker span {
  position: relative;
  display: inline-block;
  margin: 0 5px; }
  #bouncer_modal_datepicker span:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 38px;
    background: #5c5555;
    border-radius: 0 4px 4px 0;
    pointer-events: none; }
  #bouncer_modal_datepicker span:after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #aeaaaa transparent transparent transparent;
    pointer-events: none; }
  #bouncer_modal_datepicker span select {
    box-sizing: border-box;
    width: 100%;
    color: #aeaaaa;
    font-size: 12px;
    padding: 8px 48px 8px 10px;
    font-family: "Raleway", sans-serif;
    background-color: #d6d4d4;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #5c5555;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none; }

#bouncer_modal_submit {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: #f9f4ee;
  background-color: #5d5b57;
  padding: 9px 14px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
  border: 0; }
  #bouncer_modal_submit:hover {
    background-color: #060606;
    color: #f9f4ee; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * *
ShopPad App: Uploadery
https://apps.shopify.com/uploadery
* * * * * * * * * * * * * * * * * * * * * * * * * * */
#uploadery-container {
  margin-bottom: 20px; }
  #uploadery-container:empty {
    display: none !important; }
  #uploadery-container.side-labels label > div {
    display: inline-block;
    min-height: 30px;
    position: absolute;
    top: 0;
    left: 74px;
    width: 50%;
    padding-top: 0;
    margin-top: 0; }
    @media (max-width: 719px) {
      #uploadery-container.side-labels label > div {
        display: block; } }
    #uploadery-container.side-labels label > div input {
      left: 0;
      top: 10px;
      width: 100%; }
  #uploadery-container.side-labels input {
    position: absolute;
    top: 0;
    left: 74px;
    width: 50%; }
    @media (max-width: 719px) {
      #uploadery-container.side-labels input {
        position: relative;
        top: auto;
        left: auto;
        width: 100%; } }
  #uploadery-container form {
    display: inline-block;
    width: 100%;
    padding-top: 12px;
    margin-bottom: 20px;
    box-shadow: 0 -1px 0 0 #4f4949;
    background-color: inherit; }
  #uploadery-container label {
    position: relative;
    font-size: 14px;
    color: #362c2c;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    min-height: 40px; }
    #uploadery-container label .spb-fileupload {
      margin-top: 5px; }
  #uploadery-container input[type=file] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    font-size: 14px;
    color: #362c2c; }
    #uploadery-container input[type=file]::-webkit-file-upload-button {
      font-size: 14px;
      color: #362c2c;
      font-family: "Raleway", sans-serif;
      background-color: white;
      border: 1px solid #5c5555;
      border-radius: 4px;
      color: #aeaaaa;
      box-sizing: border-box;
      padding: 4px 6px;
      outline: none; }
  #uploadery-container .uploaderyProgressBar {
    max-width: 200px;
    display: inline-block; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * *
ShopPad App: Infinite Options
https://apps.shopify.com/custom-options
* * * * * * * * * * * * * * * * * * * * * * * * * * */
#infiniteoptions-container:empty {
  display: none !important; }
#infiniteoptions-container.side-labels > div > label {
  width: 54px;
  margin-right: 20px;
  float: left;
  text-align: right; }
  @media (max-width: 719px) {
    #infiniteoptions-container.side-labels > div > label {
      width: 100%;
      float: none;
      text-align: left; } }
#infiniteoptions-container.side-labels > div > span {
  width: 50%; }
  @media (max-width: 719px) {
    #infiniteoptions-container.side-labels > div > span {
      width: 100%; } }
#infiniteoptions-container > div {
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
  padding-top: 12px;
  border-top: 1px solid #4f4949; }
  #infiniteoptions-container > div > label {
    font-size: 14px;
    color: #362c2c;
    margin-bottom: 5px;
    width: 100%;
    display: inline-block; }
  #infiniteoptions-container > div > span {
    width: 100%;
    display: inline-block; }
    #infiniteoptions-container > div > span label {
      /* radio button labels */
      font-size: 14px;
      color: #362c2c;
      margin-bottom: 5px;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      position: relative; }
      #infiniteoptions-container > div > span label input[type=radio] {
        float: left;
        margin-right: 10px;
        display: inline-block;
        background-color: white;
        box-shadow: 0 0 0 1px #5c5555;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        outline: none; }
        #infiniteoptions-container > div > span label input[type=radio]:checked:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          background-color: #aeaaaa;
          border-radius: 10px;
          top: 5px;
          left: 5px; }
#infiniteoptions-container .spb-select {
  position: relative; }
  #infiniteoptions-container .spb-select:before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 38px;
    background: #5c5555;
    border-radius: 0 4px 4px 0;
    pointer-events: none; }
  #infiniteoptions-container .spb-select:after {
    content: '';
    display: block;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #aeaaaa transparent transparent transparent;
    pointer-events: none; }
  #infiniteoptions-container .spb-select select {
    box-sizing: border-box;
    width: 100%;
    color: #aeaaaa;
    font-size: 12px;
    padding: 8px 10px;
    font-family: "Raleway", sans-serif;
    background-color: #d6d4d4;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #5c5555;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none; }
#infiniteoptions-container input[type=text],
#infiniteoptions-container input[type=number],
#infiniteoptions-container input[type=url],
#infiniteoptions-container input[type=password],
#infiniteoptions-container input[type=email],
#infiniteoptions-container textarea {
  box-sizing: border-box;
  width: 100%;
  color: #aeaaaa;
  font-size: 12px;
  padding: 8px 10px;
  font-family: "Raleway", sans-serif;
  background-color: white;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #5c5555;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none; }
#infiniteoptions-container input[type=number]::-webkit-inner-spin-button,
#infiniteoptions-container input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
#infiniteoptions-container textarea {
  min-height: 75px; }
#infiniteoptions-container fieldset {
  border: 0; }
  #infiniteoptions-container fieldset label {
    font-size: 14px;
    color: #362c2c;
    margin-bottom: 5px;
    width: 100%;
    display: inline-block; }
  #infiniteoptions-container fieldset input[type=checkbox] {
    float: left;
    margin-right: 10px;
    display: inline-block;
    background-color: white;
    box-shadow: 0 0 0 1px #5c5555;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none; }
    #infiniteoptions-container fieldset input[type=checkbox]:checked:after {
      content: '\2713';
      position: absolute;
      width: 20px;
      height: 20px;
      color: #aeaaaa;
      top: 0;
      left: 0;
      text-align: center;
      line-height: 20px; }

/* * * * * * * * * * * * * * * * * * * * * * * * * * *
ShopPad App: Coin
https://apps.shopify.com/coin
* * * * * * * * * * * * * * * * * * * * * * * * * * */
.main-header #coin-container {
  display: inline-block;
  position: relative;
  float: left;
  overflow: hidden; }
  .main-header #coin-container:empty {
    display: none !important; }
  .main-header #coin-container:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4px 0 4px;
    border-color: #aeaaaa rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    pointer-events: none; }
  .main-header #coin-container label {
    font-size: 12px;
    color: #b38d41;
    font-family: "Open Sans",sans-serif; }
  .main-header #coin-container select {
    display: inline-block;
    width: auto;
    background-color: transparent;
    border: 0;
    font-size: 12px;
    color: #b38d41;
    margin: 0 0 0 10px;
    padding-right: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none; }
    .main-header #coin-container select::-ms-expand {
      display: none; }

.mobile-dropdown {
  /* #coin-container { */ }
  .mobile-dropdown #mob-coin-container {
    position: relative;
    display: block;
    width: 100%;
    min-width: 290px;
    overflow: hidden;
    min-height: 1px; }
    .mobile-dropdown #mob-coin-container:empty {
      display: none !important; }
    .mobile-dropdown #mob-coin-container:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 22px;
      right: 13%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
      pointer-events: none; }
    .mobile-dropdown #mob-coin-container select {
      display: block;
      width: 84%;
      min-width: 290px;
      background-color: transparent;
      border: 0;
      font-size: 12px;
      color: #fff;
      margin: 0 auto;
      padding: 19px 15px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      font-size: 16px; }
      .mobile-dropdown #mob-coin-container select::-ms-expand {
        display: none; }
    .mobile-dropdown #mob-coin-container label {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
      display: block;
      width: 84%;
      min-width: 290px;
      margin: 0 auto;
      padding: 19px 15px; }
      .mobile-dropdown #mob-coin-container label select {
        margin: 0;
        padding: 0;
        width: 100%;
        min-width: 100%; }

/* scss-combine:templates/404 */
.template-404 .content {
  padding-bottom: 60px; }
.template-404 .message {
  width: 50%;
  margin: 40px 0; }
  @media (max-width: 719px) {
    .template-404 .message {
      width: 100%;
      margin-top: 0px;
      text-align: center; } }

/* scss-combine:templates/account */
.customer .empty {
  margin-top: 0px; }
.customer .message {
  font-size: 14px;
  font-style: italic;
  color: #aeaaaa;
  margin: 20px 0 50px; }
@media (max-width: 719px) {
  .customer .page-title {
    border-bottom: 1px solid #5c5555; } }
.customer .back-link {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  color: #aeaaaa;
  margin-bottom: 20px;
  display: none; }
  @media (max-width: 719px) {
    .customer .back-link {
      display: inline-block; } }
.customer .header-link {
  float: right;
  margin-top: 20px;
  font-size: 12px;
  color: #aeaaaa; }
  @media (max-width: 719px) {
    .customer .header-link {
      float: none;
      margin-top: 25px;
      display: inline-block; } }
.customer .account-form {
  width: 38%;
  padding: 25px 0 100px; }
  @media (max-width: 719px) {
    .customer .account-form {
      width: 100%;
      padding: 10px 0 55px;
      text-align: center; } }
  .customer .account-form .error-message {
    height: auto;
    margin: 0 0 25px;
    text-align: center;
    width: 100%; }
    @media (max-width: 719px) {
      .customer .account-form .error-message {
        margin-top: 25px; } }
    .customer .account-form .error-message span {
      margin-top: 12px 0;
      display: inline-block; }
  .customer .account-form .field-wrap {
    margin-top: 20px; }
    .customer .account-form .field-wrap.author {
      margin-top: 0; }
    .customer .account-form .field-wrap label {
      cursor: auto;
      display: block;
      font-size: 14px;
      margin-bottom: 12px; }
      @media (max-width: 719px) {
        .customer .account-form .field-wrap label {
          text-align: left; } }
    .customer .account-form .field-wrap .field {
      width: 100%; }
    .customer .account-form .field-wrap textarea {
      min-height: 210px; }
  .customer .account-form .submit-button {
    margin-top: 30px;
    padding: 10px 14px 9px; }
    @media (max-width: 719px) {
      .customer .account-form .submit-button {
        width: 100%;
        margin-top: 35px; } }
  .customer .account-form .helper {
    margin: 0 0 8px 15px;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    color: #aeaaaa;
    display: inline-block;
    vertical-align: bottom; }
    @media (max-width: 719px) {
      .customer .account-form .helper {
        margin: 30px 0 0; } }
.customer .table {
  width: 100%;
  border: 1px solid #5c5555; }
  .customer .table th {
    background: #d6d4d4;
    padding: 22px 0;
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
    .customer .table th.first {
      padding: 22px 0px 22px 30px; }
    @media (max-width: 719px) {
      .customer .table th {
        display: none; } }
  .customer .table td {
    padding: 32px 30px 32px 0;
    border-top: 1px solid #5c5555;
    font-size: 14px;
    vertical-align: middle;
    position: relative; }
    .customer .table td.first {
      padding: 32px 30px; }
    .customer .table td:before {
      display: none;
      width: 25%;
      margin-right: 20px;
      color: #aeaaaa;
      text-align: right; }
    @media (max-width: 719px) {
      .customer .table td {
        display: inline-block;
        vertical-align: top;
        border-top: none; }
        .customer .table td.first {
          border-top: 1px solid #5c5555; }
        .customer .table td:before {
          display: inline-block;
          vertical-align: top; } }

.customer.account .content-wrap {
  margin: 50px 0 85px;
  min-height: 200px; }
.customer.account .title {
  display: block;
  font-size: 14px;
  margin-bottom: 20px; }
  @media (max-width: 719px) {
    .customer.account .title {
      text-align: center; } }
.customer.account .account-info, .customer.account .order-history {
  float: left; }
  @media (max-width: 719px) {
    .customer.account .account-info, .customer.account .order-history {
      float: none; } }
.customer.account .account-info {
  font-size: 14px;
  width: 30%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    .customer.account .account-info {
      width: 100%;
      padding-right: 0px;
      margin-bottom: 40px;
      text-align: center; } }
  .customer.account .account-info .name, .customer.account .account-info .email, .customer.account .account-info .address, .customer.account .account-info .city, .customer.account .account-info .country, .customer.account .account-info .view-addresses {
    display: block; }
  .customer.account .account-info .name {
    font-weight: 700;
    color: #b38d41;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    margin-bottom: 12px; }
  .customer.account .account-info .email {
    margin-bottom: 25px; }
    .customer.account .account-info .email a {
      color: #5d5b57;
      font-size: 12px; }
  .customer.account .account-info .address-wrap {
    margin-bottom: 25px; }
  .customer.account .account-info .view-addresses {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa; }
.customer.account .order-history {
  width: 70%; }
  @media (max-width: 719px) {
    .customer.account .order-history {
      width: 100%; } }
.customer.account .table.orders .order-number {
  width: 31.25%; }
  .customer.account .table.orders .order-number a {
    color: #5d5b57;
    font-size: 16px; }
  .customer.account .table.orders .order-number .date {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa;
    display: inline-block; }
    @media (max-width: 719px) {
      .customer.account .table.orders .order-number .date {
        display: inline; } }
  .customer.account .table.orders .order-number:before {
    content: "Order"; }
.customer.account .table.orders .payment-status {
  width: 25%; }
  .customer.account .table.orders .payment-status:before {
    content: "Payment"; }
.customer.account .table.orders .fulfillment-status {
  width: 25%; }
  .customer.account .table.orders .fulfillment-status:before {
    content: "Filled?"; }
.customer.account .table.orders .total {
  width: 18.75%;
  color: #b38d41; }
  .customer.account .table.orders .total:before {
    content: "Total"; }
@media (max-width: 719px) {
  .customer.account .table.orders .order-number, .customer.account .table.orders .payment-status, .customer.account .table.orders .fulfillment-status, .customer.account .table.orders .total {
    width: 100%;
    padding: 12px 12px 12px 30px; }
  .customer.account .table.orders .first {
    padding-top: 30px; }
  .customer.account .table.orders .last {
    padding-bottom: 30px; } }

/* scss-combine:templates/activate */
/* scss-combine:templates/addresses */
.customer.addresses {
  padding-bottom: 80px; }
  @media (max-width: 719px) {
    .customer.addresses {
      padding-bottom: 50px; } }
  .customer.addresses .address-list {
    width: 75%;
    padding: 15px 0 0px; }
    @media (max-width: 719px) {
      .customer.addresses .address-list {
        width: 100%;
        padding-top: 0;
        text-align: center; } }
    .customer.addresses .address-list .list li {
      padding: 35px 0;
      border-bottom: 1px solid #4f4949; }
      .customer.addresses .address-list .list li.last {
        padding-bottom: 40px; }
    .customer.addresses .address-list .name {
      font-size: 14px;
      font-weight: 700;
      color: #b38d41;
      font-family: "PT Serif", "Times New Roman", Times, serif;
      margin: 0 0 25px; }
      .customer.addresses .address-list .name .default {
        font-size: 12px;
        font-weight: 400;
        color: #aeaaaa;
        margin-left: 2px; }
    .customer.addresses .address-list .address-1, .customer.addresses .address-list .address-2, .customer.addresses .address-list .address-3 {
      font-size: 14px;
      margin: 3px 0; }
    .customer.addresses .address-list .action {
      display: block;
      margin-top: 25px; }
      .customer.addresses .address-list .action a {
        font-family: "Raleway", sans-serif;
        font-size: 12px;
        color: #aeaaaa; }
        .customer.addresses .address-list .action a:first-child {
          margin-right: 15px; }
  .customer.addresses .add-new {
    display: block;
    font-size: 14px;
    color: #5d5b57;
    margin-top: 45px; }
    @media (max-width: 719px) {
      .customer.addresses .add-new {
        text-align: center; } }
  .customer.addresses .add-address {
    margin-top: 45px;
    padding-top: 40px;
    width: 75%;
    border-top: 1px solid #5c5555; }
    @media (max-width: 719px) {
      .customer.addresses .add-address {
        width: 100%; } }
    .customer.addresses .add-address.new-user {
      display: block !important;
      padding-top: 0;
      border-top: 0; }
  @media (max-width: 719px) {
    .customer.addresses .edit-add-address {
      text-align: left; } }
  .customer.addresses .edit-add-address .wrap {
    width: 75%;
    margin-bottom: 22px; }
    @media (max-width: 719px) {
      .customer.addresses .edit-add-address .wrap {
        width: 100%; } }
    .customer.addresses .edit-add-address .wrap > label {
      display: inline-block;
      font-size: 14px;
      margin-bottom: 12px; }
    .customer.addresses .edit-add-address .wrap .field {
      display: block;
      width: 100%; }
    .customer.addresses .edit-add-address .wrap .styled-select, .customer.addresses .edit-add-address .wrap .pxuSexyDropWrapper {
      display: block;
      width: 330px;
      height: 32px; }
      @media (max-width: 719px) {
        .customer.addresses .edit-add-address .wrap .styled-select, .customer.addresses .edit-add-address .wrap .pxuSexyDropWrapper {
          width: 100%; } }
    .customer.addresses .edit-add-address .wrap .styled-select {
      top: 5px; }
  .customer.addresses .edit-add-address .default-wrap {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa;
    margin: 35px 0 45px; }
    .customer.addresses .edit-add-address .default-wrap input, .customer.addresses .edit-add-address .default-wrap span {
      display: inline-block; }
    .customer.addresses .edit-add-address .default-wrap input {
      margin-right: 4px; }
  .customer.addresses .edit-add-address .submit-wrap span {
    font-family: "Raleway", sans-serif;
    color: #aeaaaa;
    font-size: 12px;
    margin-left: 15px; }
    .customer.addresses .edit-add-address .submit-wrap span a {
      color: #362c2c; }
  @media (max-width: 719px) {
    .customer.addresses .edit-add-address .submit-wrap {
      text-align: center; }
      .customer.addresses .edit-add-address .submit-wrap .action-button {
        width: 100%; }
      .customer.addresses .edit-add-address .submit-wrap span {
        display: block;
        margin: 25px 0 15px; } }

/* scss-combine:templates/blog */
/* Both blog and article template */
.template-blog .content-wrap, .template-article .content-wrap {
  padding-top: 40px;
  padding-bottom: 80px; }
  @media (max-width: 719px) {
    .template-blog .content-wrap, .template-article .content-wrap {
      padding-top: 0;
      padding-bottom: 0; } }
.template-blog .articles, .template-article .articles {
  float: left;
  width: 75%;
  padding-right: 3%; }
  @media (max-width: 719px) {
    .template-blog .articles, .template-article .articles {
      width: 100%;
      border-bottom: 1px solid #5c5555;
      padding-bottom: 15px;
      padding-right: 0px; } }
  .template-blog .articles .meta, .template-article .articles .meta {
    float: left;
    width: 30%;
    padding-right: 6%;
    position: relative; }
    @media (max-width: 719px) {
      .template-blog .articles .meta, .template-article .articles .meta {
        width: 100%;
        border-bottom: 1px solid #4f4949;
        margin-bottom: 35px;
        padding-right: 0px; } }
    .template-blog .articles .meta li, .template-article .articles .meta li {
      margin-bottom: 28px;
      padding-top: 28px;
      border-top: 1px solid #4f4949; }
      @media (max-width: 719px) {
        .template-blog .articles .meta li, .template-article .articles .meta li {
          border-top: none; } }
      .template-blog .articles .meta li label, .template-blog .articles .meta li .text, .template-blog .articles .meta li .text a, .template-article .articles .meta li label, .template-article .articles .meta li .text, .template-article .articles .meta li .text a {
        color: #aeaaaa; }
      .template-blog .articles .meta li label, .template-article .articles .meta li label {
        display: block;
        font-size: 12px;
        cursor: auto; }
        .template-blog .articles .meta li label a, .template-article .articles .meta li label a {
          color: #aeaaaa; }
          .template-blog .articles .meta li label a:hover, .template-article .articles .meta li label a:hover {
            color: #9d9999; }
      .template-blog .articles .meta li .text, .template-article .articles .meta li .text {
        font-family: "Raleway", sans-serif;
        font-size: 12px; }
        .template-blog .articles .meta li .text a:hover, .template-article .articles .meta li .text a:hover {
          color: #9d9999; }
      .template-blog .articles .meta li.title, .template-article .articles .meta li.title {
        display: none; }
        @media (max-width: 719px) {
          .template-blog .articles .meta li.title, .template-article .articles .meta li.title {
            display: block;
            text-align: center;
            border-top: 1px solid #4f4949;
            padding: 40px 0 0;
            margin-bottom: 25px; } }
      .template-blog .articles .meta li.date, .template-article .articles .meta li.date {
        border-top: 0;
        padding-top: 0; }
        @media (max-width: 719px) {
          .template-blog .articles .meta li.date, .template-article .articles .meta li.date {
            width: 100%;
            text-align: center;
            padding-bottom: 35px;
            margin-bottom: 0px; } }
        .template-blog .articles .meta li.date label, .template-article .articles .meta li.date label {
          font-size: 14px; }
      @media (max-width: 719px) {
        .template-blog .articles .meta li.author, .template-blog .articles .meta li.tags, .template-blog .articles .meta li.comments, .template-article .articles .meta li.author, .template-article .articles .meta li.tags, .template-article .articles .meta li.comments {
          display: none; } }
      .template-blog .articles .meta li.author .avatar-wrap, .template-article .articles .meta li.author .avatar-wrap {
        width: 54px;
        height: 54px;
        overflow: hidden;
        margin-bottom: 15px;
        border-radius: 54px; }
      .template-blog .articles .meta li.author .avatar, .template-article .articles .meta li.author .avatar {
        width: 54px; }
        @media (max-width: 719px) {
          .template-blog .articles .meta li.author .avatar, .template-article .articles .meta li.author .avatar {
            width: 46px; } }
      @media (max-width: 719px) {
        .template-blog .articles .meta li.tags, .template-article .articles .meta li.tags {
          float: right;
          padding-right: 20px;
          text-align: center; } }
      .template-blog .articles .meta li.prev-next-post .label, .template-article .articles .meta li.prev-next-post .label {
        display: inline; }
      .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
        display: none; }
      @media (max-width: 719px) {
        .template-blog .articles .meta li.prev-next-post, .template-article .articles .meta li.prev-next-post {
          height: 0;
          width: 0;
          padding: 0;
          margin: 0; }
          .template-blog .articles .meta li.prev-next-post .label, .template-article .articles .meta li.prev-next-post .label {
            display: none; }
          .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
            display: block; }
          .template-blog .articles .meta li.prev-next-post .previous, .template-blog .articles .meta li.prev-next-post .next, .template-article .articles .meta li.prev-next-post .previous, .template-article .articles .meta li.prev-next-post .next {
            position: absolute;
            height: 22px;
            width: 22px;
            top: 50%;
            margin-top: -11px;
            font-family: 'icons'; }
          .template-blog .articles .meta li.prev-next-post .next, .template-article .articles .meta li.prev-next-post .next {
            left: 0; }
          .template-blog .articles .meta li.prev-next-post .previous, .template-article .articles .meta li.prev-next-post .previous {
            right: 0; }
          .template-blog .articles .meta li.prev-next-post .slash, .template-article .articles .meta li.prev-next-post .slash {
            display: none; }
          .template-blog .articles .meta li.prev-next-post .icon, .template-article .articles .meta li.prev-next-post .icon {
            font-family: 'icons';
            font-size: 50px;
            color: #4f4949;
            line-height: 16px;
            text-indent: 0px;
            text-transform: lowercase; }
          .template-blog .articles .meta li.prev-next-post .next .icon, .template-article .articles .meta li.prev-next-post .next .icon {
            text-indent: 2px; }
          .template-blog .articles .meta li.prev-next-post .previous .icon, .template-article .articles .meta li.prev-next-post .previous .icon {
            text-indent: -11px; } }
      @media (max-width: 719px) {
        .template-blog .articles .meta li.share-buttons, .template-article .articles .meta li.share-buttons {
          display: none; } }
      .template-blog .articles .meta li.share-buttons .share-wrap, .template-article .articles .meta li.share-buttons .share-wrap {
        width: 100%;
        height: 42px;
        display: inline-block;
        vertical-align: top; }
        .template-blog .articles .meta li.share-buttons .share-wrap > iframe, .template-blog .articles .meta li.share-buttons .share-wrap > div, .template-article .articles .meta li.share-buttons .share-wrap > iframe, .template-article .articles .meta li.share-buttons .share-wrap > div {
          display: inline-block;
          vertical-align: top; }
        .template-blog .articles .meta li.share-buttons .share-wrap.first, .template-article .articles .meta li.share-buttons .share-wrap.first {
          border-top: 1px solid #5c5555; }
        .template-blog .articles .meta li.share-buttons .share-wrap.facebook .fb_iframe_widget span, .template-article .articles .meta li.share-buttons .share-wrap.facebook .fb_iframe_widget span {
          vertical-align: top; }
    .template-blog .articles .meta.mobile, .template-article .articles .meta.mobile {
      display: none; }
      @media (max-width: 719px) {
        .template-blog .articles .meta.mobile, .template-article .articles .meta.mobile {
          display: block;
          border-top: 1px solid #4f4949;
          border-bottom: none;
          float: none;
          margin-bottom: 0;
          margin-top: 10px; } }
      .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
        display: none; }
        @media (max-width: 719px) {
          .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
            display: block;
            width: 50%;
            margin: 0;
            padding: 20px 0; }
            .template-blog .articles .meta.mobile li.author label, .template-blog .articles .meta.mobile li.tags label, .template-article .articles .meta.mobile li.author label, .template-article .articles .meta.mobile li.tags label {
              margin-top: 5px; } }
        @media (max-width: 480px) {
          .template-blog .articles .meta.mobile li.author, .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.tags {
            width: 100%;
            text-align: center; } }
      @media (max-width: 719px) {
        .template-blog .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.author {
          float: left;
          padding-left: 20px; }
          .template-blog .articles .meta.mobile li.author .avatar-wrap, .template-article .articles .meta.mobile li.author .avatar-wrap {
            float: left;
            width: 46px;
            height: 46px;
            margin: 0 10px 0 0; }
          .template-blog .articles .meta.mobile li.author .avatar, .template-article .articles .meta.mobile li.author .avatar {
            width: 46px; } }
      @media (max-width: 480px) {
        .template-blog .articles .meta.mobile li.author, .template-article .articles .meta.mobile li.author {
          float: none; }
          .template-blog .articles .meta.mobile li.author .avatar-wrap, .template-article .articles .meta.mobile li.author .avatar-wrap {
            float: none;
            margin: 0 auto 10px; } }
      @media (max-width: 480px) {
        .template-blog .articles .meta.mobile li.tags, .template-article .articles .meta.mobile li.tags {
          padding-top: 0px; } }
      .template-blog .articles .meta.mobile li.share-buttons, .template-article .articles .meta.mobile li.share-buttons {
        display: block;
        width: 100%;
        padding-top: 30px;
        margin-bottom: 30px;
        float: left;
        text-align: center;
        border-top: 1px solid #5c5555; }
        .template-blog .articles .meta.mobile li.share-buttons .share-wrap, .template-article .articles .meta.mobile li.share-buttons .share-wrap {
          width: auto;
          height: 20px;
          margin: 0 8px; }
  .template-blog .articles .article, .template-article .articles .article {
    border-top: 1px solid #4f4949;
    padding-top: 40px;
    margin-bottom: 25px; }
    @media (max-width: 719px) {
      .template-blog .articles .article, .template-article .articles .article {
        border-top: none;
        margin: 0 0 30px;
        padding: 0; } }
    .template-blog .articles .article.first, .template-article .articles .article.first {
      border-top: none;
      padding-top: 0; }
    .template-blog .articles .article .article-content, .template-article .articles .article .article-content {
      float: right;
      width: 70%; }
      @media (max-width: 719px) {
        .template-blog .articles .article .article-content, .template-article .articles .article .article-content {
          width: 100%; } }
      .template-blog .articles .article .article-content .title, .template-article .articles .article .article-content .title {
        display: block; }
        @media (max-width: 719px) {
          .template-blog .articles .article .article-content .title, .template-article .articles .article .article-content .title {
            display: none; } }
      .template-blog .articles .article .article-content .rte img, .template-article .articles .article .article-content .rte img {
        max-width: 100%; }
    .template-blog .articles .article .article-image, .template-article .articles .article .article-image {
      margin-bottom: 20px; }
    .template-blog .articles .article .title, .template-article .articles .article .title {
      margin-top: -3px;
      margin-bottom: 30px; }
      .template-blog .articles .article .title, .template-blog .articles .article .title a, .template-article .articles .article .title, .template-article .articles .article .title a {
        color: #b38d41;
        font-family: "PT Serif", "Times New Roman", Times, serif;
        font-size: 28px;
        font-weight: 700;
        line-height: 1.2; }
      .template-blog .articles .article .title a:hover, .template-article .articles .article .title a:hover {
        color: #5d5b57; }
.template-blog .articles .pagination, .template-article .articles .pagination {
  width: 75%;
  float: right;
  display: block; }
  @media (max-width: 719px) {
    .template-blog .articles .pagination, .template-article .articles .pagination {
      display: none; } }
.template-blog .mobile .pagination, .template-article .mobile .pagination {
  margin-bottom: 45px;
  padding-bottom: 0;
  display: none; }
  @media (max-width: 719px) {
    .template-blog .mobile .pagination, .template-article .mobile .pagination {
      display: block; } }

/* Just the article template */
.template-article .articles .article {
  border-top: none;
  padding-top: 0px; }
.template-article .articles .comments-wrap {
  border-top: 1px solid #5c5555;
  padding-top: 40px;
  margin-bottom: 45px; }
  @media (min-width: 720px) {
    .template-article .articles .comments-wrap {
      width: 100%;
      float: right;
      margin-bottom: 20px; } }
  .template-article .articles .comments-wrap.no-comments {
    margin-bottom: 35px; }
  .template-article .articles .comments-wrap .title {
    display: block !important;
    font-size: 14px;
    font-weight: 400;
    color: #b38d41; }
    .template-article .articles .comments-wrap .title .count {
      color: #aeaaaa; }
  @media (min-width: 720px) {
    .template-article .articles .comments-wrap.read {
      margin-top: 20px; } }
  .template-article .articles .comments-wrap.read .comments {
    margin: 35px 0 25px; }
  .template-article .articles .comments-wrap.read .comment {
    padding: 25px 0;
    border-top: 1px solid #4f4949; }
    .template-article .articles .comments-wrap.read .comment:first-child {
      padding: 0 0 25px;
      border-top: none; }
    .template-article .articles .comments-wrap.read .comment.last {
      padding: 25px 0 0; }
    .template-article .articles .comments-wrap.read .comment .date {
      display: block;
      font-size: 12px;
      color: #aeaaaa;
      margin-bottom: 10px; }
      .template-article .articles .comments-wrap.read .comment .date:hover {
        color: #9d9999; }
    .template-article .articles .comments-wrap.read .comment .body p {
      font-size: 14px;
      line-height: 22px;
      font-style: italic;
      margin: 0 0 20px; }
    .template-article .articles .comments-wrap.read .comment .author {
      font-family: "Raleway", sans-serif;
      font-size: 12px;
      color: #aeaaaa;
      width: 100%;
      text-align: right;
      margin: 25px 0 0; }
  @media (max-width: 719px) {
    .template-article .articles .comments-wrap.submit {
      margin-bottom: 45px; } }
  .template-article .articles .comments-wrap.submit .error-message {
    margin: 25px 0;
    text-align: center; }
    .template-article .articles .comments-wrap.submit .error-message span {
      margin-top: 12px;
      display: inline-block; }
  .template-article .articles .comments-wrap.submit .title {
    margin-bottom: 35px; }
  .template-article .articles .comments-wrap.submit .field-wrap {
    margin-top: 20px; }
    @media (min-width: 720px) {
      .template-article .articles .comments-wrap.submit .field-wrap {
        width: 75%; } }
    .template-article .articles .comments-wrap.submit .field-wrap:first-child {
      margin-top: 0; }
    .template-article .articles .comments-wrap.submit .field-wrap label {
      cursor: auto;
      display: block;
      font-size: 14px;
      margin-bottom: 12px; }
    .template-article .articles .comments-wrap.submit .field-wrap .field {
      width: 100%; }
    .template-article .articles .comments-wrap.submit .field-wrap textarea {
      min-height: 210px; }
  .template-article .articles .comments-wrap.submit .submit-button {
    margin-top: 30px;
    padding: 10px 14px 9px; }
    @media (max-width: 719px) {
      .template-article .articles .comments-wrap.submit .submit-button {
        width: 100%; } }

/* scss-combine:templates/cart */
.template-cart .content {
  padding-bottom: 65px; }
.template-cart .table {
  width: 100%;
  margin: 40px 0 30px;
  border: 1px solid #5c5555; }
  @media (max-width: 719px) {
    .template-cart .table {
      margin-top: 0px; } }
  .template-cart .table th {
    background: #d6d4d4;
    padding: 22px 0;
    font-size: 14px;
    font-weight: 400;
    text-align: left; }
    .template-cart .table th.first {
      padding: 22px 0px 22px 30px; }
    @media (max-width: 719px) {
      .template-cart .table th {
        display: none; } }
  @media (max-width: 719px) {
    .template-cart .table, .template-cart .table tr, .template-cart .table td, .template-cart .table tbody {
      display: block;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      clear: left; } }
  .template-cart .table td {
    padding: 32px 30px 32px 0;
    border-top: 1px solid #5c5555;
    vertical-align: middle;
    position: relative; }
    @media (max-width: 719px) {
      .template-cart .table td {
        display: block;
        border-top: none; } }
    .template-cart .table td.first {
      padding: 32px 30px; }
      @media (max-width: 719px) {
        .template-cart .table td.first {
          border-top: 1px solid #5c5555; } }
    .template-cart .table td.product-item {
      width: 42.8%;
      font-size: 0; }
      @media (max-width: 719px) {
        .template-cart .table td.product-item {
          width: 100%;
          padding: 30px 12px 12px 30px; } }
      .template-cart .table td.product-item .image-wrap {
        width: 25%;
        display: inline-block;
        vertical-align: middle; }
        .template-cart .table td.product-item .image-wrap .image {
          width: 100%;
          position: relative;
          display: inline-block; }
          .template-cart .table td.product-item .image-wrap .image .outline {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 1px solid rgba(0, 0, 0, 0.1); }
          .template-cart .table td.product-item .image-wrap .image img {
            width: 100%; }
      .template-cart .table td.product-item .wrap {
        font-size: 1em;
        width: 75%;
        padding-left: 20px;
        padding-right: 35px;
        position: relative;
        display: inline-block;
        vertical-align: middle; }
      .template-cart .table td.product-item .label {
        display: block; }
        .template-cart .table td.product-item .label.vendor {
          color: #aeaaaa;
          font-size: 10px;
          margin-bottom: 10px; }
        .template-cart .table td.product-item .label.title {
          margin-bottom: 15px; }
          .template-cart .table td.product-item .label.title a {
            color: #5d5b57;
            font-size: 16px; }
        .template-cart .table td.product-item .label.variant {
          font-family: "Raleway", sans-serif;
          font-size: 12px; }
    .template-cart .table td.price {
      width: 17.2%;
      font-size: 14px; }
      .template-cart .table td.price:before {
        content: "Price"; }
      @media (max-width: 719px) {
        .template-cart .table td.price {
          width: 100%;
          padding: 12px 12px 12px 30px; } }
    .template-cart .table td.quantity {
      width: 17.2%; }
      @media (max-width: 719px) {
        .template-cart .table td.quantity {
          width: 100%;
          padding: 12px 12px 12px 30px; } }
      .template-cart .table td.quantity:before {
        content: 'Quantity';
        margin-top: 2px; }
      .template-cart .table td.quantity .field {
        width: 56px;
        text-align: center;
        color: #aeaaaa; }
        @media (max-width: 719px) {
          .template-cart .table td.quantity .field {
            padding: 3px 0;
            width: 25px; } }
    .template-cart .table td.total {
      width: 17.2%;
      font-size: 14px;
      color: #b38d41; }
      .template-cart .table td.total:before {
        content: "Total"; }
      @media (max-width: 719px) {
        .template-cart .table td.total {
          width: 100%;
          padding: 12px 12px 30px 30px; } }
    .template-cart .table td.remove {
      width: 5.6%; }
      @media (max-width: 719px) {
        .template-cart .table td.remove {
          position: absolute;
          right: 35px;
          padding: 0;
          width: 13px; } }
      .template-cart .table td.remove a {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 12px;
        height: 15px;
        font-family: 'icons';
        color: #4f4949;
        line-height: 10px;
        text-indent: -8px;
        font-size: 47px; }
        @media (max-width: 719px) {
          .template-cart .table td.remove a {
            top: 17px; } }
        .template-cart .table td.remove a:hover {
          color: #5c5555; }
    .template-cart .table td:before {
      display: none;
      width: 25%;
      margin-right: 30px;
      font-size: 14px;
      color: #aeaaaa;
      text-align: right; }
      @media (max-width: 719px) {
        .template-cart .table td:before {
          display: inline-block;
          vertical-align: top; } }
.template-cart .item-properties {
  margin-top: 15px;
  font-family: "Raleway", sans-serif;
  font-size: 12px; }
.template-cart .item-property {
  margin-top: 5px; }
.template-cart .cart-tools {
  padding-bottom: 40px; }
  .template-cart .cart-tools .instructions {
    float: left;
    width: 48.5%; }
    @media (max-width: 719px) {
      .template-cart .cart-tools .instructions {
        display: block;
        width: 100%;
        margin-bottom: 35px; } }
    .template-cart .cart-tools .instructions p {
      margin: 0 0 15px; }
    .template-cart .cart-tools .instructions .field {
      width: 100%;
      min-height: 86px; }
  .template-cart .cart-tools .totals {
    float: right;
    width: 240px; }
    @media (max-width: 719px) {
      .template-cart .cart-tools .totals {
        width: 100%; } }
    .template-cart .cart-tools .totals .price {
      font-size: 28px;
      color: #b38d41;
      margin: 0; }
    .template-cart .cart-tools .totals .message {
      font-size: 14px;
      font-style: italic;
      color: #aeaaaa;
      margin: 10px 0 25px; }
    .template-cart .cart-tools .totals .checkout {
      width: 100%;
      border-top: 1px solid #4f4949;
      padding-top: 20px; }
      .template-cart .cart-tools .totals .checkout .action-button {
        color: #ffffff;
        margin-bottom: 10px; }
        @media (max-width: 719px) {
          .template-cart .cart-tools .totals .checkout .action-button {
            width: 100%;
            text-align: center; } }

.cart-update {
  display: none; }
  .touch .cart-update {
    display: inline-block; }

.additional-checkout-buttons {
  display: block; }
  .additional-checkout-buttons .additional-checkout-button {
    margin-left: 0 !important; }
  .additional-checkout-buttons > * {
    float: left; }

.shopify-apple-pay-button {
  margin-left: 0 !important; }

/* scss-combine:templates/collection */
.template-collection .content {
  padding-bottom: 65px; }
  @media (max-width: 719px) {
    .template-collection .content {
      padding-bottom: 50px; } }
.template-collection .page-title {
  position: relative;
  white-space: nowrap; }
  .template-collection .page-title .label {
    display: inline;
    white-space: normal; }
    @media (max-width: 719px) {
      .template-collection .page-title .label {
        display: block; } }
  .template-collection .page-title .tags-wrap {
    display: inline-block;
    vertical-align: top;
    margin-top: 18px; }
    .template-collection .page-title .tags-wrap.preload {
      position: absolute;
      left: -99999px; }
    @media (max-width: 719px) {
      .template-collection .page-title .tags-wrap {
        margin-top: 30px; } }
  .template-collection .page-title .pxuSexyDropWrapper {
    display: none;
    position: absolute !important;
    right: 0;
    top: 25px; }
    .template-collection .page-title .pxuSexyDropWrapper.show {
      display: inline-block; }
    @media (max-width: 719px) {
      .template-collection .page-title .pxuSexyDropWrapper {
        display: block !important;
        position: relative !important;
        right: auto;
        top: auto; } }
    .template-collection .page-title .pxuSexyDropWrapper select {
      top: 4px !important; }
  .template-collection .page-title .tags {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 16px;
    margin-left: 28px; }
    .template-collection .page-title .tags:before {
      content: '';
      position: absolute;
      left: 0;
      top: -12px;
      bottom: -8px;
      width: 1px;
      background: #4f4949; }
    @media (max-width: 719px) {
      .template-collection .page-title .tags {
        display: none !important; } }
    .template-collection .page-title .tags .tag {
      display: none;
      font-size: 14px;
      margin: 0 12px; }
      .template-collection .page-title .tags .tag.show {
        display: inline-block; }
      .template-collection .page-title .tags .tag a {
        color: #b38d41; }
      .template-collection .page-title .tags .tag a:hover {
        color: #5d5b57; }
.template-collection .collection-header {
  float: left;
  width: 100%; }
  .template-collection .collection-header .description {
    text-align: center;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%; }
    @media (max-width: 719px) {
      .template-collection .collection-header .description {
        float: none;
        width: 100%;
        margin-top: 0px;
        padding-right: 0px;
        text-align: center; } }
    @media screen and (min-width: 400px) {
      .template-collection .collection-header .description {
        max-width: 80%; } }
    @media screen and (min-width: 800px) {
      .template-collection .collection-header .description {
        max-width: 60%; } }
    @media screen and (min-width: 1100px) {
      .template-collection .collection-header .description {
        max-width: 550px; } }
  .template-collection .collection-header .pagination {
    float: right;
    text-align: right; }
    @media (max-width: 719px) {
      .template-collection .collection-header .pagination {
        display: none; } }
.template-collection .product-list {
  float: left;
  width: 100%;
  padding: 50px 0 0; }
  @media screen and (max-width: 1000px) {
    .template-collection .product-list {
      min-width: 100%; } }
  .template-collection .product-list .product {
    margin-bottom: 42px; }
    @media (max-width: 719px) {
      .template-collection .product-list .product {
        margin-bottom: 35px; } }
  .template-collection .product-list.row-of-2 .product {
    width: 50%; }
  .template-collection .product-list.row-of-3 .product {
    width: 33.3%; }
    @media screen and (max-width: 1000px) {
      .template-collection .product-list.row-of-3 .product {
        width: 50%; } }
  .template-collection .product-list.row-of-4 .product {
    width: 25%; }
    @media screen and (max-width: 1000px) {
      .template-collection .product-list.row-of-4 .product {
        width: 50%; } }
.template-collection .product-list-withsidebar {
  float: right;
  width: 80%; }
  @media screen and (max-width: 1200px) {
    .template-collection .product-list-withsidebar.row-of-3 .product {
      width: 33.3%; }
    .template-collection .product-list-withsidebar.row-of-4 .product {
      width: 50%; } }
.template-collection .product-list-sidebar {
  padding-top: 50px;
  font-size: 1rem;
  float: left;
  width: 20%; }
  .template-collection .product-list-sidebar a .remove {
    padding-left: 10px;
    color: #aeaaaa; }
  .template-collection .product-list-sidebar a:hover .remove {
    color: inherit; }
@media screen and (max-width: 700px) {
  .template-collection .product-list-sidebar,
  .template-collection .product-list-withsidebar {
    width: 100%;
    padding-bottom: 50px; }
  .template-collection .product-list-sidebar {
    text-align: center; } }

.collection-heading-tools {
  padding-top: 50px;
  border-top: 1px solid #5c5555; }

.collection-heading-details {
  margin-top: 30px;
  margin-bottom: 30px; }

.collection-heading-details,
.collection-title {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px; }
  .centering-wrapper .collection-heading-details, .centering-wrapper
  .collection-title {
    margin: 0; }
  .collection-heading-details .description,
  .collection-title .description {
    margin-right: auto;
    margin-left: auto;
    max-width: 90%; }
    @media screen and (min-width: 400px) {
      .collection-heading-details .description,
      .collection-title .description {
        max-width: 80%; } }
    @media screen and (min-width: 800px) {
      .collection-heading-details .description,
      .collection-title .description {
        max-width: 60%; } }
    @media screen and (min-width: 1100px) {
      .collection-heading-details .description,
      .collection-title .description {
        max-width: 550px; } }
  .collection-heading-details .rte,
  .collection-title .rte {
    word-wrap: break-word; }

.collection-title-header {
  position: relative;
  float: left;
  width: 100%; }
  .collection-title-header.page-title .label {
    color: #5d5b57;
    font-size: 11vw;
    line-height: 0.825em;
    word-break: break-word; }
    @media screen and (max-width: 400px) {
      .collection-title-header.page-title .label {
        font-size: 42px; } }
    @media screen and (min-width: 1400px) {
      .collection-title-header.page-title .label {
        font-size: 160px; } }

.collection-title .label {
  margin: 30px 0; }

.tools-wrap {
  display: inline-block;
  margin-right: 1em; }
  .tools-wrap select {
    color: inherit;
    background: none;
    border: 0;
    text-decoration: underline;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
  .tools-wrap select::-ms-expand {
    display: none; }
  .tools-wrap svg {
    display: none; }

.collection-tools-left,
.collection-tools-right {
  width: 50%;
  white-space: nowrap; }

.collection-tools-left {
  float: left;
  font-family: "Raleway", sans-serif;
  font-size: 16px; }

.collection-tools-right {
  float: right; }

.product-list-sidebar {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  line-height: 25px; }
  .product-list-sidebar .collection-sorter .sort-dropdown {
    white-space: nowrap;
    display: block; }
  .product-list-sidebar .collection-sorter select {
    color: inherit;
    margin-top: 1em;
    border: 0;
    background-color: transparent;
    padding-right: 17px;
    margin-right: -17px;
    max-width: 100%;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .product-list-sidebar .collection-sorter select:focus {
      outline-offset: 10px; }
  .product-list-sidebar .collection-sorter svg {
    width: 10px;
    height: 6px;
    display: inline-block;
    margin-left: 0;
    vertical-align: middle;
    pointer-events: none; }
  .product-list-sidebar .collection-sorter select::-ms-expand {
    display: none; }

.sidebar-title, .product-list-sidebar .collection-sorter label {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 1em; }
  .sidebar-title:not(:first-child), .product-list-sidebar .collection-sorter label:not(:first-child) {
    margin-top: 3em; }

.product-list-sidebar a {
  color: inherit; }

/* scss-combine:templates/gift-card */
.gift-card-template {
  @-webkit-keyframes slideup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(2000px) rotate(10deg); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-30px); }

    80% {
      -webkit-transform: translateY(10px); }

    100% {
      -webkit-transform: translateY(0) rotate(0deg); } }

  @-ms-keyframes slideup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(2000px) rotate(10deg); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-30px); }

    80% {
      -webkit-transform: translateY(10px); }

    100% {
      -webkit-transform: translateY(0) rotate(0deg); } }

  @keyframes slideup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(2000px) rotate(10deg); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-30px); }

    80% {
      -webkit-transform: translateY(10px); }

    100% {
      -webkit-transform: translateY(0) rotate(0deg); } }

  @-webkit-keyframes popup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(30px); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-10px); }

    80% {
      -webkit-transform: translateY(2px); }

    100% {
      -webkit-transform: translateY(0); } }

  @-ms-keyframes popup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(30px); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-10px); }

    80% {
      -webkit-transform: translateY(2px); }

    100% {
      -webkit-transform: translateY(0); } }

  @keyframes popup {
    0% {
      opacity: 0;
      -webkit-transform: translateY(30px); }

    60% {
      opacity: 1;
      -webkit-transform: translateY(-10px); }

    80% {
      -webkit-transform: translateY(2px); }

    100% {
      -webkit-transform: translateY(0); } }

  @-webkit-keyframes container-slide {
    0% {
      opacity: 0;
      -webkit-transform: rotate(0deg); }

    100% {
      -webkit-transform: rotate(0deg); } }

  @-ms-keyframes container-slide {
    0% {
      opacity: 0;
      -webkit-transform: rotate(0deg); }

    100% {
      -webkit-transform: rotate(0deg); } }

  @keyframes container-slide {
    0% {
      opacity: 0;
      -webkit-transform: rotate(0deg); }

    100% {
      -webkit-transform: rotate(0deg); } }

  @-webkit-keyframes fadein {
    0% {
      opacity: 0; }

    100% {
      opacity: 100; } }

  @-ms-keyframes fadein {
    0% {
      opacity: 0; }

    100% {
      opacity: 100; } }

  @keyframes fadein {
    0% {
      opacity: 0; }

    100% {
      opacity: 100; } }
 }
  .gift-card-template .btn {
    background-color: #58686f;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 1.5em;
    font-size: 0.875em;
    line-height: 1;
    border-radius: 4px;
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto; }
  .gift-card-template .btn:hover {
    background-color: #414d53; }
  .gift-card-template .wrap {
    width: 95%;
    max-width: 540px;
    margin: 0 auto; }
    .gift-card-template .wrap:after {
      content: "";
      display: table;
      clear: both; }
  .gift-card-template header {
    text-align: center;
    padding: 3em 0;
    -webkit-animation: fadein 0.5s ease-in-out both 0.4s;
    animation: fadein 0.5s ease-in-out both 0.4s; }
  .gift-card-template .shop-title {
    font-size: 2.25em;
    font-weight: 600;
    color: #bfbfbf;
    max-width: 300px;
    display: block;
    margin: 0 auto; }
    .gift-card-template .shop-title:hover {
      color: #999999; }
    .gift-card-template .shop-title img {
      max-height: 100%;
      margin: 0 auto; }
  .gift-card-template main {
    -webkit-animation: slideup 0.8s ease-in-out;
    animation: slideup 0.8s ease-in-out;
    padding-bottom: 3em; }
  .gift-card-template .gift-card-outer-container {
    background-color: #34aadc;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
    padding: 1em;
    -webkit-animation: container-slide 0.8s ease-in-out;
    animation: container-slide 0.8s ease-in-out; }
  .gift-card-template .gift-card-inner-container {
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    -webkit-animation: cardslide 0.8s ease-in-out;
    animation: cardslide 0.8s ease-in-out; }
    .gift-card-template .gift-card-inner-container:after {
      content: "";
      display: table;
      clear: both; }
  .gift-card-template .gift-card-header {
    border-bottom: 1px solid #f2f2f2;
    padding: 0.75em;
    display: block;
    overflow: hidden;
    position: relative; }
    .gift-card-template .gift-card-header h2 {
      float: left;
      margin: 0.12em 0; }
    .gift-card-template .gift-card-header .tag {
      float: right; }
  .gift-card-template .tag {
    background-color: #bfbfbf;
    padding: 0.5em;
    padding-bottom: 0.35em;
    border-radius: 4px;
    font-size: 0.75em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #fff;
    line-height: 1; }
    .gift-card-template .tag.light {
      background: transparent;
      color: #bfbfbf;
      border: 1px solid #d9d9d9; }
  .gift-card-template .gift-card-holder {
    margin: 0.75em;
    margin-bottom: 1.25em;
    margin-top: 1.25em;
    position: relative; }
    .gift-card-template .gift-card-holder .corner {
      display: block;
      width: 47px;
      height: 47px;
      position: absolute;
      z-index: 2; }
      .gift-card-template .gift-card-holder .corner.top-left {
        background: url("//cdn.shopify.com/s/assets/gift-card/corner-top-left-1585103bdd46bf1297b88f31bdfce999.png") 0 0 no-repeat;
        top: -1px;
        left: -1px; }
      .gift-card-template .gift-card-holder .corner.bottom-right {
        background: url("//cdn.shopify.com/s/assets/gift-card/corner-bottom-right-ba899b18631cb91859e186c2cc1c6970.png") 0 0 no-repeat;
        bottom: -1px;
        right: -1px; }
  .gift-card-template .gift-card {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative; }
    .gift-card-template .gift-card:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      z-index: 1;
      pointer-events: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      display: block; }
    .gift-card-template .gift-card img {
      border-radius: 10px;
      max-width: 100%; }
  .gift-card-template .gift-card-code-outer {
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100%; }
    .gift-card-template .gift-card-code-outer.medium {
      font-size: 0.875em; }
    .gift-card-template .gift-card-code-outer.small {
      font-size: 0.75em; }
  .gift-card-template .gift-card-code-inner {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    background-color: #fff;
    padding: 0.5em;
    border-radius: 4px;
    max-width: 450px;
    -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); }
    .gift-card-template .gift-card-code-inner strong {
      font-weight: 400;
      font-size: 1.875em;
      text-transform: uppercase;
      border-radius: 2px;
      border: 1px dashed #e5e5e5;
      padding: 0.4em 0.5em;
      display: inline-block;
      vertical-align: baseline;
      zoom: 1;
      *display: inline;
      *vertical-align: auto;
      color: #777;
      line-height: 1; }
  .gift-card-template .small .gift-card-code-inner {
    overflow: auto; }
  .gift-card-template .disabled .gift-card-code-inner strong {
    color: #999;
    text-decoration: line-through; }
  .gift-card-template .gift-card-code-inner span + span {
    margin-left: 0.25em; }
  .gift-card-template .gift-card-amount {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 2.75em;
    padding: 0.4em 0.5em; }
  .gift-card-template .gift-card-amount.medium {
    font-size: 2.25em; }
  .gift-card-template .gift-card-amount strong {
    text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
    display: block; }
  .gift-card-template .tooltip {
    position: relative; }
    .gift-card-template .tooltip:hover .tooltip-container {
      display: block; }
  .gift-card-template .tooltip-container {
    display: block;
    position: absolute;
    top: -100%;
    right: 50%;
    z-index: 3;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    -webkit-animation: popup 0.5s ease-in-out both 0.7s;
    animation: popup 0.5s ease-in-out both 0.7s;
    top: -50%;
    margin-top: 0.25em; }
  .gift-card-template .tooltip-triangle {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 5px solid #333;
    border-top: 5px solid rgba(51, 51, 51, 0.9);
    position: absolute;
    left: 100%;
    bottom: 0;
    margin-left: -5px;
    margin-bottom: -5px; }
  .gift-card-template .tooltip-label {
    display: block;
    position: relative;
    right: -50%;
    border: none;
    border-radius: 4px;
    background: #333;
    background: rgba(51, 51, 51, 0.9);
    color: #fff;
    min-height: 14px;
    font-weight: 400;
    font-size: 0.4em;
    text-decoration: none;
    line-height: 16px;
    text-shadow: none;
    padding: 0.5em 0.75em;
    margin-left: 0.25em; }
  .gift-card-template .gift-card-instructions {
    font-size: 0.875em;
    text-align: center;
    color: #999;
    margin: 0 0.75em 1.5em; }
  .gift-card-template .gift-card-qr-code {
    display: block; }
    .gift-card-template .gift-card-qr-code img {
      padding: 1.25em;
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      margin: 0 auto 1.25em; }
  .gift-card-template .gift-card-actions {
    border-top: 1px solid #f2f2f2;
    padding: 1.25em 0.75em;
    text-align: center;
    position: relative;
    display: block;
    overflow: hidden; }
  .gift-card-template .action-link {
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: #bfbfbf;
    margin-top: 1.75em;
    position: absolute;
    left: 1.25em;
    top: 1.25em; }
    .gift-card-template .action-link:hover {
      color: #999; }
      .gift-card-template .action-link:hover .ico-16 {
        opacity: 0.4; }
  .gift-card-template .ico-16 {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    *display: inline;
    *vertical-align: auto;
    width: 17px;
    height: 17px;
    vertical-align: bottom;
    margin-bottom: -1px;
    background-position: 0 0;
    background-repeat: no-repeat;
    margin-right: 0.5em;
    opacity: 0.25;
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out; }
    .gift-card-template .ico-16.print {
      background-image: url("//cdn.shopify.com/s/assets/gift-card/icon-print-164daa1ae32d10d1f9b83ac21b6f2c70.png"); }
  .gift-card-template footer[role='contentinfo'] {
    padding-bottom: 3em;
    text-align: center;
    -webkit-animation: fadein 0.5s ease-in-out both 0.4s;
    animation: fadein 0.5s ease-in-out both 0.4s; }
  .gift-card-template .gift-card-apple-wallet-badge {
    display: inline-block; }
  .gift-card-template .gift-card-apple-wallet-badge-image {
    display: block; }
  @media screen and (max-width: 580px) {
    .gift-card-template body {
      font-size: 12px; }
    .gift-card-template h2 {
      font-size: 1.5em; }
    .gift-card-template .gift-card-outer-container {
      padding: 0.5em; }
    .gift-card-template .tooltip-container {
      top: -65%; }
    .gift-card-template .gift-card-actions .btn {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      font-size: 1.125em; }
    .gift-card-template .action-link {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      font-size: 1.125em;
      display: none; }
    .gift-card-template .action-link + .action-link {
      margin-left: 1.5em; } }
  @media screen and (max-width: 400px) {
    .gift-card-template h2 {
      font-size: 1.25em; }
    .gift-card-template .gift-card {
      font-size: 10px; }
    .gift-card-template .gift-card-holder .corner {
      display: none; }
      .gift-card-template .gift-card-holder .corner.bottom-right {
        background-position: bottom right !important; }
    .gift-card-template .gift-card-amount strong {
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1); }
    .gift-card-template .tooltip-container {
      top: -75%; } }
  @media screen and (max-height: 800px) {
    .gift-card-template .shop-title {
      max-height: 100px; } }
  @media screen and (max-height: 750px) {
    .gift-card-template .shop-title {
      max-height: 80px; }
    .gift-card-template header {
      padding: 2em 0; }
    .gift-card-template footer[role='contentinfo'],
    .gift-card-template main {
      padding-bottom: 2em; } }
  @media print {
    .gift-card-template {
      @page {
        margin: 0.5cm; }
 }
      .gift-card-template p,
      .gift-card-template h2,
      .gift-card-template h3 {
        orphans: 3;
        widows: 3; }
      .gift-card-template h2,
      .gift-card-template h3 {
        page-break-after: avoid; }
      .gift-card-template body {
        background-color: #fff; }
      .gift-card-template .gift-card-actions,
      .gift-card-template .gift-card-holder .corner,
      .gift-card-template .tooltip-container,
      .gift-card-template .gift-card:before {
        display: none; }
      .gift-card-template .gift-card-code-inner strong {
        color: #555; }
      .gift-card-template .gift-card-amount .tooltip {
        color: #fff !important; }
      .gift-card-template .shop-title {
        color: #58686f; }
      .gift-card-template .gift-card-outer-container,
      .gift-card-template .gift-card-inner-container,
      .gift-card-template .gift-card-code-inner,
      .gift-card-template .gift-card:before {
        -webkit-box-shadow: none;
        box-shadow: none; } }

/* scss-combine:templates/home */
.template-index .slideshow {
  overflow: hidden;
  position: relative;
  transition: height 1s ease; }
  @media (max-width: 719px) {
    .template-index .slideshow.content-area {
      padding: 0;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box; } }
  .template-index .slideshow.content-area .slide::before {
    content: none; }
  .no-js .template-index .slideshow {
    display: none; }
.template-index .slide {
  width: 100%;
  float: left;
  margin-right: -100%;
  overflow: hidden;
  position: relative;
  z-index: 1000;
  text-align: center;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0); }
  .template-index .slide.active {
    z-index: 2000; }
  .template-index .slide::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2500; }
.template-index .slide .image-wrap {
  position: relative; }
.template-index .slide img,
.template-index .slide svg {
  width: 100%; }
.template-index .slide .jump-to-slide {
  position: absolute;
  bottom: 2px;
  left: 10px;
  z-index: 6000;
  display: block;
  height: 32px;
  max-width: 150px;
  overflow: hidden; }
  .template-index .slide .jump-to-slide li {
    display: inline-block;
    height: 11px;
    width: 11px;
    margin: 0 8px;
    background-color: #ffffff;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    border-radius: 11px; }
    .template-index .slide .jump-to-slide li.active, .template-index .slide .jump-to-slide li:hover {
      background-color: rgba(255, 255, 255, 0.7); }
.template-index .slide.invert .jump-to-slide li {
  background-color: rgba(0, 0, 0, 0.5); }
  .template-index .slide.invert .jump-to-slide li.active, .template-index .slide.invert .jump-to-slide li:hover {
    background-color: rgba(0, 0, 0, 0.7); }
.template-index .slide .prev, .template-index .slide .next {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 3000;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  cursor: pointer; }
  .template-index .slide .prev .icon, .template-index .slide .next .icon {
    font-family: 'icons';
    font-size: 99px;
    color: #ffffff;
    line-height: 62px;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50); }
    .template-index .slide .prev .icon:hover, .template-index .slide .next .icon:hover {
      opacity: 0.75;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
      filter: alpha(opacity=75); }
  @media (max-width: 938px) {
    .template-index .slide .prev, .template-index .slide .next {
      width: 39px;
      height: 39px;
      top: auto;
      bottom: 1px; }
      .template-index .slide .prev .icon, .template-index .slide .next .icon {
        font-size: 65px;
        line-height: 40px; } }
.template-index .slide.invert .icon, .template-index .slide.invert .icon {
  color: #000000; }
.template-index .slide .prev {
  left: 0; }
  @media (max-width: 938px) {
    .template-index .slide .prev {
      left: auto;
      right: 40px; } }
.template-index .slide .next {
  right: 0; }
.template-index .slide .content-outer-wrap {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 84%;
  max-width: 1430px; }
  @media (max-width: 719px) {
    .template-index .slide .content-outer-wrap {
      position: relative;
      left: 0;
      background-color: #5d5b57;
      width: 100%; } }
.template-index .slide .mobile-link {
  display: none; }
  @media (max-width: 719px) {
    .template-index .slide .mobile-link {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000; } }
.template-index .slide .content-inner-wrap {
  position: absolute;
  left: -50%;
  top: 0;
  bottom: 0;
  width: 100%; }
  @media (max-width: 719px) {
    .template-index .slide .content-inner-wrap {
      position: static; } }
.template-index .slide .content {
  position: absolute;
  text-align: left;
  padding: 0 15px; }
  @media (max-width: 719px) {
    .template-index .slide .content {
      position: static;
      text-align: center;
      padding: 20px 15px; } }
  .template-index .slide .content.left {
    left: 0; }
  .template-index .slide .content.bottom {
    bottom: 10%; }
  .template-index .slide .content.top {
    top: 10%; }
  .template-index .slide .content.center {
    left: 0;
    right: 0;
    text-align: center; }
  .template-index .slide .content.right {
    right: 0;
    text-align: right; }
    @media (max-width: 719px) {
      .template-index .slide .content.right {
        text-align: center; } }
  .template-index .slide .content .title {
    font-size: 56px;
    font-weight: 700;
    color: #ffffff;
    cursor: default;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33); }
    @media (max-width: 719px) {
      .template-index .slide .content .title {
        font-size: 36px;
        white-space: nowrap;
        overflow-x: hidden;
        text-shadow: none; } }
    @media (max-width: 480px) {
      .template-index .slide .content .title {
        font-size: 21px; } }
  .template-index .slide .content .tagline {
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 4px;
    cursor: default;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.33); }
    @media (max-width: 719px) {
      .template-index .slide .content .tagline {
        font-size: 16px;
        margin-top: 10px;
        overflow-x: hidden;
        text-shadow: none; } }
    @media (max-width: 480px) {
      .template-index .slide .content .tagline {
        font-size: 14px; } }
  .template-index .slide .content .call-to-action {
    padding: 20px;
    line-height: 1.0em;
    position: relative;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #f9f4ee;
    background-color: #5d5b57;
    margin-top: 24px;
    display: inline-block; }
    .template-index .slide .content .call-to-action:hover {
      background-color: #060606; }
    @media (max-width: 719px) {
      .template-index .slide .content .call-to-action {
        font-size: 12px;
        margin-top: 0;
        white-space: nowrap;
        padding-bottom: 0px; } }
    @media (max-width: 480px) {
      .template-index .slide .content .call-to-action {
        font-size: 10px; } }
    .template-index .slide .content .call-to-action .arrow {
      font-family: 'icons';
      font-size: 22px; }
      @media (max-width: 719px) {
        .template-index .slide .content .call-to-action .arrow {
          font-size: 18px; } }
      @media (max-width: 480px) {
        .template-index .slide .content .call-to-action .arrow {
          font-size: 15px; } }
.template-index .featured-text-container {
  text-align: center;
  padding: 55px 15px 0; }
  @media (max-width: 719px) {
    .template-index .featured-text-container {
      padding: 30px 15px 35px; } }
  .template-index .featured-text-container.feature-borders .featured-text {
    padding: 55px 0;
    border-top: 1px solid #5c5555; }
    @media (max-width: 719px) {
      .template-index .featured-text-container.feature-borders .featured-text {
        padding: 30px 15px 35px; } }
    .template-index .featured-text-container.feature-borders .featured-text:first-of-type {
      padding-top: 0;
      border: 0; }
    .template-index .featured-text-container.feature-borders .featured-text:last-of-type {
      padding-bottom: 0; }
  .template-index .featured-text-container h2, .template-index .featured-text-container h3 {
    display: inline-block;
    width: 70%;
    margin: 10px 0; }
    .template-index .featured-text-container h2 p, .template-index .featured-text-container h3 p {
      margin: 0; }
  .template-index .featured-text-container h2 {
    font-size: 28px;
    font-weight: 700;
    color: #b38d41;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    line-height: 33px; }
    @media (max-width: 719px) {
      .template-index .featured-text-container h2 {
        font-size: 21px; } }
  .template-index .featured-text-container h3 {
    display: inline-block;
    font-size: 18px;
    font-weight: 400;
    color: #362c2c;
    line-height: 29px; }
    @media (max-width: 719px) {
      .template-index .featured-text-container h3 {
        font-size: 16px;
        line-height: 25px; } }
.template-index .featured-text + .featured-text {
  margin-top: 30px; }
.template-index .feature-borders .featured-text + .featured-text {
  margin-top: 0; }
.template-index .featured-collections {
  text-align: center; }
  .template-index .featured-collections.first .section-title {
    border-top: 0px;
    padding-top: 75px; }
  .template-index .featured-collections .wrap {
    margin-left: -30px;
    font-size: 0px; }
    @media (max-width: 719px) {
      .template-index .featured-collections .wrap {
        margin-left: 0; } }
.template-index .featured-collection {
  text-align: center; }
  .template-index .featured-collection.first .section-title {
    border-top: 0px;
    padding-top: 75px; }
  .template-index .featured-collection.multi-row .product {
    margin-bottom: 42px; }
  .template-index .featured-collection .product-list.row-of-2 .product {
    width: 50%; }
  .template-index .featured-collection .product-list.row-of-3 .product {
    width: 33.3%; }
    @media screen and (max-width: 1000px) {
      .template-index .featured-collection .product-list.row-of-3 .product {
        width: 50%;
        margin-bottom: 42px; } }
  .template-index .featured-collection .product-list.row-of-4 .product {
    width: 25%; }
    @media screen and (max-width: 1000px) {
      .template-index .featured-collection .product-list.row-of-4 .product {
        width: 50%;
        margin-bottom: 42px; } }
.template-index .articles {
  margin-left: -30px;
  font-size: 0; }
  @media (max-width: 719px) {
    .template-index .articles {
      margin-left: 0; } }
.template-index .article {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  text-align: left;
  padding-left: 30px; }
  .template-index .article.first {
    margin-left: 0; }
  @media (max-width: 719px) {
    .template-index .article {
      display: block;
      width: 60%;
      min-width: 290px;
      margin: 0 auto 30px;
      margin-bottom: 30px;
      padding-left: 0px; }
      .template-index .article.first {
        margin: 0 auto 30px; }
      .template-index .article.last {
        margin-bottom: 0px; } }
  .template-index .article .date {
    display: block;
    font-size: 12px;
    color: #aeaaaa;
    margin-bottom: 10px; }
  .template-index .article .title {
    display: block;
    font-weight: 700;
    font-size: 16px;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    margin-bottom: 18px;
    color: #b38d41; }
    .template-index .article .title:hover {
      color: #5d5b57; }
  .template-index .article .rte {
    color: #362c2c;
    font-size: 14px;
    line-height: 1.6; }
    .template-index .article .rte img {
      width: 100%; }
  .template-index .article .article-image {
    display: block;
    margin-bottom: 20px; }

.logo-list-container {
  margin: 0 -20px; }

.logo-list {
  font-size: 0;
  text-align: center; }

.logo-list-item {
  display: inline-block;
  padding: 10px 20px;
  vertical-align: middle; }
  .rows-of-3 .logo-list-item {
    width: 33.333%; }
  .rows-of-4 .logo-list-item {
    width: 25%; }
  @media (max-width: 719px) {
    .rows-of-3 .logo-list-item, .rows-of-4 .logo-list-item {
      width: 50%; } }
  .logo-list-item .logo-list-item-wrap {
    max-width: 240px;
    margin: 0 auto; }
  .logo-list-item img,
  .logo-list-item svg {
    max-width: 100%; }

.home-section {
  position: relative;
  padding-top: 65px;
  margin-top: 65px; }
  .slideshow-sibling-section .home-section {
    margin-top: 0; }
  .home-section > .section-title {
    padding-top: 0; }
  .home-section.has-heading {
    padding-top: 50px; }
  .home-section.has-border::before {
    position: absolute;
    top: 0;
    display: block;
    width: calc(100% - 30px);
    border-top: 1px solid #5c5555;
    content: ""; }
  .home-section.has-border > .section-title {
    padding-top: 0; }
  .home-section.multi-row {
    margin-bottom: -42px; }
  .home-section.picture-block, .home-section.slideshow:not(.content-area) {
    padding-top: 0; }
    .home-section.picture-block::before, .home-section.slideshow:not(.content-area)::before {
      display: none; }
  .home-section.slideshow.content-area {
    padding-top: 0; }

.template-index .shopify-section:not(:first-of-type) .slide::before {
  display: none; }
.template-index .shopify-section:first-of-type [data-section-type] > *:first-child {
  margin-top: 0; }
  .template-index .shopify-section:first-of-type [data-section-type] > *:first-child.slideshow.content-area {
    margin-top: 65px; }
    @media (max-width: 719px) {
      .template-index .shopify-section:first-of-type [data-section-type] > *:first-child.slideshow.content-area {
        margin-top: 0; } }
  .template-index .shopify-section:first-of-type [data-section-type] > *:first-child::before {
    display: none; }
.template-index .shopify-section:first-of-type .home-video:first-of-type {
  padding-top: 0; }

.instagram-widget {
  position: relative; }

.instagram-photos {
  margin: 0 -10px;
  font-size: 0;
  background: url("//isabellaadams.com/cdn/shop/t/8/assets/loading.gif?v=171368479448122837701579199173") no-repeat center; }
  .instagram-photos.visible {
    background: none; }
  @media (max-width: 719px) {
    .instagram-photos {
      margin: 0 25px; } }

.instagram-photo {
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  margin: 0 10px;
  width: calc(16.666% - 20px); }
  @media (min-width: 720px) and (max-width: 960px) {
    .instagram-photo {
      width: calc(33.333% - 20px);
      margin-bottom: 20px; } }
  @media (max-width: 719px) {
    .instagram-photo {
      width: calc(33.333% - 20px);
      margin-bottom: 20px; } }
  @media (max-width: 480px) {
    .instagram-photo {
      width: calc(50% - 20px);
      margin-bottom: 20px; } }
  @media (min-width: 720px) and (max-width: 960px) {
    .instagram-photo:nth-child(n+4) {
      margin-bottom: 0; } }
  @media (max-width: 719px) {
    .instagram-photo:nth-child(n+4) {
      margin-bottom: 0; } }
  @media (max-width: 480px) {
    .instagram-photo:nth-child(n+4) {
      margin-bottom: 20px; } }
  .instagram-photo:nth-child(n+7) {
    display: none; }
  .visible .instagram-photo {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out; }
  .instagram-photo img {
    max-width: 100%;
    display: block; }

.twitter-tweet {
  margin: 0 auto;
  max-width: 60%;
  line-height: 1.375em;
  text-align: center;
  word-wrap: break-word;
  font-size: 18px; }
  @media screen and (min-width: 500px) {
    .twitter-tweet {
      font-size: 20px; } }
  @media screen and (min-width: 900px) {
    .twitter-tweet {
      font-size: 22px; } }
  @media screen and (min-width: 1200px) {
    .twitter-tweet {
      font-size: 24px; } }
  .twitter-tweet img {
    height: 14px;
    vertical-align: sub; }
  .twitter-tweet .tweet-wrap:nth-child(n+2) {
    display: none; }
  .twitter-tweet .timestamp {
    display: block;
    margin-top: 2em;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    color: #aeaaaa; }
    .twitter-tweet .timestamp .divider {
      margin: 0 10px; }
    .twitter-tweet .timestamp img,
    .twitter-tweet .timestamp span[data-scribe='element:name'] {
      display: none; }
    @media (max-width: 480px) {
      .twitter-tweet .timestamp .divider {
        display: none; }
      .twitter-tweet .timestamp span[data-scribe='element:screen_name'] {
        display: block; } }
    .twitter-tweet .timestamp [data-scribe="component:author"] {
      display: inline-block; }

.paused .prev,
.paused .next {
  opacity: 0; }

/* scss-combine:templates/login */
.customer.login #recover-password {
  display: none; }
.customer.login .secondary-wrap {
  width: 100%;
  padding-top: 35px;
  margin-top: 35px;
  border-top: 1px solid #5c5555; }
  @media (max-width: 719px) {
    .customer.login .secondary-wrap {
      text-align: center; }
      .customer.login .secondary-wrap .action-button {
        width: 100%; } }
  .customer.login .secondary-wrap p {
    font-size: 14px;
    margin: 0 0 20px; }

/* scss-combine:templates/order */
.customer.order .content-wrap {
  margin: 50px 0 85px; }
  @media (max-width: 719px) {
    .customer.order .content-wrap {
      margin: 30px 0 55px; } }
.customer.order .shipping-info, .customer.order .order-history {
  float: left; }
  @media (max-width: 719px) {
    .customer.order .shipping-info, .customer.order .order-history {
      float: none; } }
.customer.order .shipping-info {
  font-size: 14px;
  line-height: 22px;
  width: 30%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    .customer.order .shipping-info {
      width: 100%;
      padding-right: 0px;
      margin-bottom: 40px;
      text-align: center; } }
  .customer.order .shipping-info .shipping-title {
    display: block;
    font-weight: 700;
    color: #b38d41;
    font-family: "PT Serif", "Times New Roman", Times, serif;
    margin-bottom: 10px; }
  .customer.order .shipping-info .address, .customer.order .shipping-info .city, .customer.order .shipping-info .country {
    display: block; }
  .customer.order .shipping-info .address-wrap {
    margin-bottom: 30px; }
.customer.order .order-history {
  width: 70%; }
  @media (max-width: 719px) {
    .customer.order .order-history {
      width: 100%; } }
  @media (max-width: 719px) {
    .customer.order .order-history .table td {
      width: 100% !important;
      padding: 12px 12px 12px 30px !important; } }
  @media (max-width: 719px) {
    .customer.order .order-history .table td.first {
      padding-top: 30px !important; } }
  @media (max-width: 719px) {
    .customer.order .order-history .table td.last {
      padding-bottom: 30px !important; } }
  .customer.order .order-history .table td.product-item {
    width: 46%; }
    .customer.order .order-history .table td.product-item .wrap, .customer.order .order-history .table td.product-item .image-wrap {
      display: inline-block; }
    .customer.order .order-history .table td.product-item .wrap {
      width: 75%;
      padding-left: 30px; }
      @media (max-width: 719px) {
        .customer.order .order-history .table td.product-item .wrap {
          padding-left: 20px; } }
    .customer.order .order-history .table td.product-item .image-wrap {
      width: 25%;
      position: relative; }
      .customer.order .order-history .table td.product-item .image-wrap img {
        width: 100%; }
      .customer.order .order-history .table td.product-item .image-wrap a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
        .customer.order .order-history .table td.product-item .image-wrap a.overlay {
          box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
    .customer.order .order-history .table td.product-item .label {
      display: block; }
      .customer.order .order-history .table td.product-item .label.vendor {
        color: #aeaaaa;
        font-size: 10px;
        margin-bottom: 10px; }
      .customer.order .order-history .table td.product-item .label.title {
        margin-bottom: 15px; }
        .customer.order .order-history .table td.product-item .label.title a {
          color: #5d5b57;
          font-size: 16px; }
      .customer.order .order-history .table td.product-item .label.variant {
        font-family: "Raleway", sans-serif;
        font-size: 12px; }
  .customer.order .order-history .table td.price {
    width: 18%; }
    .customer.order .order-history .table td.price:before {
      content: "Price"; }
  .customer.order .order-history .table td.quantity {
    width: 18%;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa; }
    .customer.order .order-history .table td.quantity:before {
      content: 'Quantity';
      font-family: "PT Serif", "Times New Roman", Times, serif;
      font-size: 14px;
      color: #aeaaaa;
      top: 9px; }
    @media (max-width: 719px) {
      .customer.order .order-history .table td.quantity span {
        position: relative;
        top: 3px; } }
  .customer.order .order-history .table td.total {
    width: 18%; }
    .customer.order .order-history .table td.total:before {
      content: "Total"; }
  .customer.order .order-history .order-totals {
    width: 100%; }
    @media (max-width: 719px) {
      .customer.order .order-history .order-totals {
        display: none; } }
    .customer.order .order-history .order-totals ul {
      border-bottom: 1px solid #4f4949;
      padding: 30px 0;
      text-align: right;
      font-size: 14px; }
      .customer.order .order-history .order-totals ul.sub-total {
        padding: 30px 0 35px; }
      .customer.order .order-history .order-totals ul li {
        text-align: right;
        padding: 10px 0; }
        .customer.order .order-history .order-totals ul li.order-subtotal {
          padding-top: 0; }
        .customer.order .order-history .order-totals ul li.order-tax.last {
          padding-bottom: 0; }
        .customer.order .order-history .order-totals ul li.order-total {
          padding: 3px 0 5px; }
          .customer.order .order-history .order-totals ul li.order-total span {
            color: #b38d41; }
      .customer.order .order-history .order-totals ul h2, .customer.order .order-history .order-totals ul span {
        display: inline-block; }
      .customer.order .order-history .order-totals ul h2 {
        font-weight: 400;
        font-size: 14px; }
      .customer.order .order-history .order-totals ul span {
        width: 90px;
        text-align: left;
        margin-left: 30px; }

/* scss-combine:templates/page */
.template-page .text-content-wrap, .template-contact .text-content-wrap {
  padding-bottom: 80px;
  padding-top: 40px; }
  @media (max-width: 719px) {
    .template-page .text-content-wrap, .template-contact .text-content-wrap {
      padding-top: 0;
      padding-bottom: 50px; } }
.template-page .text-content.has-sidebar, .template-contact .text-content.has-sidebar {
  float: left;
  width: 68.5714%;
  padding-right: 30px; }
  @media (max-width: 719px) {
    .template-page .text-content.has-sidebar, .template-contact .text-content.has-sidebar {
      width: 100%;
      float: none;
      padding-right: 0px; } }
.template-page .sidebar, .template-contact .sidebar {
  float: right;
  width: 31.4286%;
  padding-left: 8.5714%; }
  @media (max-width: 719px) {
    .template-page .sidebar, .template-contact .sidebar {
      float: none;
      width: 100%;
      margin-top: 40px;
      padding-top: 30px;
      padding-left: 0px;
      border-top: 1px solid #5c5555; } }
  .template-page .sidebar .title, .template-contact .sidebar .title {
    color: #b38d41;
    font-size: 14px;
    margin-bottom: 30px; }

.template-contact .alert-message {
  margin: 0 0 25px;
  text-align: center;
  width: 100%;
  height: auto;
  padding: 12px;
  line-height: 1.5em; }
  .template-contact .alert-message span {
    display: inline-block; }
.template-contact .field-wrap {
  margin-top: 20px; }
  .template-contact .field-wrap.author {
    margin-top: 0; }
    @media (min-width: 720px) and (max-width: 960px) {
      .template-contact .field-wrap.author {
        margin-top: 20px; } }
    @media (max-width: 719px) {
      .template-contact .field-wrap.author {
        margin-top: 20px; } }
  .template-contact .field-wrap label {
    cursor: auto;
    display: block;
    font-size: 14px;
    margin-bottom: 12px; }
  .template-contact .field-wrap .field {
    width: 100%; }
  .template-contact .field-wrap textarea {
    min-height: 210px; }
.template-contact .submit-button {
  margin-top: 30px;
  padding: 10px 14px 9px; }
  @media (max-width: 719px) {
    .template-contact .submit-button {
      width: 100%; } }
.template-contact .full-width-form {
  width: 50%; }
  @media (min-width: 720px) and (max-width: 960px) {
    .template-contact .full-width-form {
      width: 75%; } }
  @media (max-width: 719px) {
    .template-contact .full-width-form {
      width: 100%; } }

/* scss-combine:templates/password */
.password-page-background {
  height: 100vh;
  text-align: center;
  background-size: cover;
  background-position: center center; }
  .lt-ie9 .password-page-background {
    height: 100%;
    min-height: 100%; }
  .password-page-background .form-title {
    margin-bottom: 15px;
    font-size: 16px; }
  .password-page-background div.errors {
    margin-top: 8px;
    margin-bottom: 8px; }
  .password-page-background .social-links {
    margin-top: 30px;
    margin-bottom: 30px; }
  .password-page-background .social-link:not(:first-child) {
    margin-left: 1em; }
  .password-page-background svg {
    display: inline-block;
    width: 32px;
    height: 32px; }

.password-page {
  display: table;
  width: 100%;
  height: 100%; }
  .lt-ie9 .password-page {
    min-width: 100%;
    min-height: 100%; }

.password-page-footer,
.password-page-header {
  display: table-row;
  height: 1px; }

.password-page-header {
  font-size: 14px;
  text-align: right; }

.lt-ie9 .admin-login-modal {
  display: none; }

.password-page-footer {
  color: #aeaaaa;
  font-size: 14px; }

.password-page-content {
  display: table-row;
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  .password-page-content h2 {
    font-family: "PT Serif", "Times New Roman", Times, serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 33px;
    color: #b38d41; }

.password-page-inner {
  display: table-cell;
  padding: 10px 15px; }
  .password-page-content .password-page-inner {
    vertical-align: middle; }
  .password-page-header .password-page-inner, .password-page-footer .password-page-inner {
    font-size: 95%;
    line-height: 1.2;
    vertical-align: bottom; }

.password-login-text {
  text-align: right; }

.password-page-logo {
  padding-bottom: 15px; }
  .password-page-logo .store-title {
    display: block;
    font-size: 28px;
    letter-spacing: 0.2em !important;
    margin: 0; }
    @media (min-width: 720px) and (max-width: 960px) {
      .password-page-logo .store-title {
        font-size: 21px; } }
    @media (max-width: 719px) {
      .password-page-logo .store-title {
        font-size: 23px;
        max-width: 100%;
        text-align: center; } }
    .password-page-logo .store-title img {
      width: 100%;
      max-width: 480px; }
      .password-page-logo .store-title img.regular-logo {
        display: inline-block; }
      .password-page-logo .store-title img.retina-logo {
        display: none;
        max-height: px; }
      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        .password-page-logo .store-title img.regular-logo {
          display: none; }
        .password-page-logo .store-title img.retina-logo {
          display: inline-block; } }
    .password-page-logo .store-title a {
      color: #222222; }
      .password-page-logo .store-title a:hover {
        color: #222222; }

.password-page-form-header {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #5c5555; }
  .password-page-form-header:after {
    content: '';
    display: block;
    max-width: 50px;
    margin: 15px auto 0;
    border-bottom: 1px solid #5c5555; }

.password-page-message {
  margin-top: 1em;
  margin-bottom: 0; }

.password-page-modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000;
  display: none;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0); }
  .lt-ie9 .password-page-modal-wrapper {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgOAMAAM8AzbfAeUUAAAAASUVORK5CYII="); }

.password-page-modal {
  width: 72%;
  max-width: 100%;
  padding: 42px 30px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2); }
  @media (max-width: 719px) {
    .password-page-modal {
      width: 100%; } }
  @media (min-width: 720px) {
    .password-page-modal {
      max-width: 1028px; } }
  .password-page-modal .header {
    position: relative;
    padding-bottom: 15px; }
  .password-page-modal .close-modal {
    position: absolute;
    top: -30px;
    right: -15px;
    font-family: 'icons';
    font-size: 3em;
    -webkit-font-smoothing: antialiased;
    line-height: 0;
    color: #ccc;
    cursor: pointer; }
  .password-page-modal .admin-login {
    font-size: 14px;
    color: #aeaaaa; }

.password-page-field-wrap {
  position: relative;
  display: inline-block;
  width: 75%;
  max-width: 350px;
  padding-right: 65px;
  margin: 0 auto;
  border: 1px solid #5c5555; }
  @media (max-width: 719px) {
    .password-page-field-wrap {
      width: 100%; } }
  .password-page-field-wrap .password-page-input,
  .password-page-field-wrap .submit {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 10px;
    border: 0;
    outline: none; }
  .password-page-field-wrap .password-page-input {
    font-size: 16px; }
  .password-page-field-wrap .password-page-input {
    width: 100%;
    padding: 8px 0 8px 8px;
    font-family: "Raleway", sans-serif;
    color: #aeaaaa;
    background: transparent; }
  .password-page-field-wrap .submit {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 65px;
    letter-spacing: 0.1em;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background: #aeaaaa;
    border-radius: 0; }
    .password-page-field-wrap .submit:hover {
      background: #9d9999; }

/* scss-combine:templates/product */
#product-area .showcase, #product-area .pager, #product-area .details, .quick-shop-content .showcase, .quick-shop-content .pager, .quick-shop-content .details {
  float: left; }
#product-area .showcase .container, #product-area .pager .container, .quick-shop-content .showcase .container, .quick-shop-content .pager .container {
  margin-bottom: 10px; }
  @media (max-width: 719px) {
    #product-area .showcase .container, #product-area .pager .container, .quick-shop-content .showcase .container, .quick-shop-content .pager .container {
      margin-bottom: 0; } }
#product-area .showcase .wrap, #product-area .pager .wrap, .quick-shop-content .showcase .wrap, .quick-shop-content .pager .wrap {
  position: relative;
  z-index: 100; }
  #product-area .showcase .wrap .overlay, #product-area .pager .wrap .overlay, .quick-shop-content .showcase .wrap .overlay, .quick-shop-content .pager .wrap .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
    -webkit-transition: box-shadow 200ms;
    -moz-transition: box-shadow 200ms;
    transition: box-shadow 200ms; }
    .lt-ie9 #product-area .showcase .wrap .overlay, .lt-ie9 #product-area .pager .wrap .overlay, .lt-ie9 .quick-shop-content .showcase .wrap .overlay, .lt-ie9 .quick-shop-content .pager .wrap .overlay {
      border: 1px solid #5c5555; }
  #product-area .showcase .wrap.active .overlay, #product-area .pager .wrap.active .overlay, .quick-shop-content .showcase .wrap.active .overlay, .quick-shop-content .pager .wrap.active .overlay {
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3); }
  #product-area .showcase .wrap img, #product-area .pager .wrap img, .quick-shop-content .showcase .wrap img, .quick-shop-content .pager .wrap img {
    width: 100%;
    height: auto;
    display: block; }
#product-area .showcase, .quick-shop-content .showcase {
  width: 50%;
  padding: 0 15px 0 0; }
  #product-area .showcase .container, .quick-shop-content .showcase .container {
    overflow: hidden;
    position: relative;
    width: 80%;
    float: left; }
    #product-area .showcase .container.wide, .quick-shop-content .showcase .container.wide {
      width: 100%; }
    #product-area .showcase .container .spinner, .quick-shop-content .showcase .container .spinner {
      position: absolute !important;
      left: 50% !important;
      top: 50% !important;
      z-index: 50 !important; }
    @media (max-width: 719px) {
      #product-area .showcase .container, .quick-shop-content .showcase .container {
        width: 100%; } }
  @media (max-width: 719px) {
    #product-area .showcase, .quick-shop-content .showcase {
      width: 100%;
      padding: 0; } }
  #product-area .showcase .notes, .quick-shop-content .showcase .notes {
    float: left;
    width: 100%; }
    @media (max-width: 719px) {
      #product-area .showcase .notes, .quick-shop-content .showcase .notes {
        display: none; } }
  #product-area .showcase .notes a, .quick-shop-content .showcase .notes a {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #aeaaaa;
    position: relative;
    padding-left: 28px;
    display: inline-block; }
    #product-area .showcase .notes a.email-us, #product-area .showcase .notes a.fitting-guide, #product-area .showcase .notes a.guides, #product-area .showcase .notes a.toggle-fullview, .quick-shop-content .showcase .notes a.email-us, .quick-shop-content .showcase .notes a.fitting-guide, .quick-shop-content .showcase .notes a.guides, .quick-shop-content .showcase .notes a.toggle-fullview {
      margin-top: 15px; }
    #product-area .showcase .notes a .icon, .quick-shop-content .showcase .notes a .icon {
      display: block;
      font-family: 'icons';
      margin-right: 5px;
      font-size: 87px;
      line-height: 10px;
      text-indent: -17px;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      overflow: hidden;
      -webkit-font-smoothing: antialiased; }
    #product-area .showcase .notes a.toggle-fullview .icon, .quick-shop-content .showcase .notes a.toggle-fullview .icon {
      font-size: 79px;
      margin-top: -3px; }
    #product-area .showcase .notes a.guides .icon, .quick-shop-content .showcase .notes a.guides .icon {
      font-size: 80px;
      text-indent: -14px; }
    #product-area .showcase .notes a.email-us .icon, #product-area .showcase .notes a.fitting-guide .icon, .quick-shop-content .showcase .notes a.email-us .icon, .quick-shop-content .showcase .notes a.fitting-guide .icon {
      font-size: 86px;
      margin-left: -1px;
      margin-top: -3px; }
#product-area .product-main-image, .quick-shop-content .product-main-image {
  position: relative;
  -webkit-transition: height 0.2s ease-in;
  -moz-transition: height 0.2s ease-in;
  transition: height 0.2s ease-in; }
  #product-area .product-main-image img, .quick-shop-content .product-main-image img {
    z-index: 100;
    display: block;
    margin: 0 auto;
    -webkit-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in; }
  #product-area .product-main-image.zoom-enabled, .quick-shop-content .product-main-image.zoom-enabled {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in; }
#product-area .image-list-item, .quick-shop-content .image-list-item {
  position: relative; }
  #product-area .image-list-item ~ .image-list-item, .quick-shop-content .image-list-item ~ .image-list-item {
    margin-top: 10px; }
#product-area .product-zoom, .quick-shop-content .product-zoom {
  position: absolute;
  background-color: white;
  background-repeat: no-repeat;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  cursor: -webkit-zoom-out;
  cursor: -moz-zoom-out;
  cursor: zoom-out; }
  #product-area .product-zoom.active, .quick-shop-content .product-zoom.active {
    display: block; }
#product-area .pager, .quick-shop-content .pager {
  float: right;
  width: 20%;
  padding: 0 0 0 30px; }
  @media (max-width: 719px) {
    #product-area .pager, .quick-shop-content .pager {
      width: 100%;
      padding: 30px 0 0;
      text-align: center; } }
  #product-area .pager .wrap, .quick-shop-content .pager .wrap {
    margin-top: 10px;
    vertical-align: top; }
    #product-area .pager .wrap.first, .quick-shop-content .pager .wrap.first {
      margin-top: 0px; }
    @media (max-width: 719px) {
      #product-area .pager .wrap, .quick-shop-content .pager .wrap {
        display: inline-block;
        width: 64px;
        margin: 0 8px 20px; } }
  #product-area .pager .overlay, .quick-shop-content .pager .overlay {
    cursor: pointer; }
#product-area .below-main-image, .quick-shop-content .below-main-image {
  width: 100%;
  padding: 0;
  font-size: 0;
  margin-bottom: 10px; }
  @media (max-width: 719px) {
    #product-area .below-main-image, .quick-shop-content .below-main-image {
      padding-top: 30px; } }
  #product-area .below-main-image .inner, .quick-shop-content .below-main-image .inner {
    margin: 0 -6px; }
  #product-area .below-main-image .wrap, .quick-shop-content .below-main-image .wrap {
    width: 20%;
    display: inline-block;
    padding: 0 6px; }
    @media (max-width: 719px) {
      #product-area .below-main-image .wrap, .quick-shop-content .below-main-image .wrap {
        width: 64px;
        margin: 0 10px 20px;
        padding: 0; } }
    #product-area .below-main-image .wrap.first, .quick-shop-content .below-main-image .wrap.first {
      margin-top: 10px; }
      @media (max-width: 719px) {
        #product-area .below-main-image .wrap.first, .quick-shop-content .below-main-image .wrap.first {
          margin-top: 0; } }
    #product-area .below-main-image .wrap .overlay, .quick-shop-content .below-main-image .wrap .overlay {
      left: 6px;
      right: 6px; }
      @media (max-width: 719px) {
        #product-area .below-main-image .wrap .overlay, .quick-shop-content .below-main-image .wrap .overlay {
          left: 0;
          right: 0; } }
#product-area .product-details-wrapper, .quick-shop-content .product-details-wrapper {
  float: left;
  width: 50%;
  padding: 0 0 0 15px; }
  @media (max-width: 719px) {
    #product-area .product-details-wrapper, .quick-shop-content .product-details-wrapper {
      width: 100%;
      padding: 30px 0 0;
      margin-top: 10px; }
      #product-area .product-details-wrapper.border, .quick-shop-content .product-details-wrapper.border {
        border-top: 1px solid #5c5555; } }
  #product-area .product-details-wrapper .header, .quick-shop-content .product-details-wrapper .header {
    padding-bottom: 30px; }
    @media (max-width: 719px) {
      #product-area .product-details-wrapper .header, .quick-shop-content .product-details-wrapper .header {
        display: none; } }
    #product-area .product-details-wrapper .header .brand, .quick-shop-content .product-details-wrapper .header .brand {
      font-size: 14px;
      margin: 0 0 0;
      color: #aeaaaa; }
    #product-area .product-details-wrapper .header .title, .quick-shop-content .product-details-wrapper .header .title {
      font-size: 35px;
      margin: 8px 0 20px; }
    #product-area .product-details-wrapper .header .price, .quick-shop-content .product-details-wrapper .header .price {
      font-size: 18px;
      margin: 0 0 0; }
      #product-area .product-details-wrapper .header .price .original, .quick-shop-content .product-details-wrapper .header .price .original {
        margin-right: 10px; }
  #product-area .product-details-wrapper .share-buttons, .quick-shop-content .product-details-wrapper .share-buttons {
    padding: 0px 0 20px; }
    @media (max-width: 719px) {
      #product-area .product-details-wrapper .share-buttons, .quick-shop-content .product-details-wrapper .share-buttons {
        display: none; } }
  #product-area .product-details-wrapper .options, #product-area .product-details-wrapper .description, .quick-shop-content .product-details-wrapper .options, .quick-shop-content .product-details-wrapper .description {
    border-top: 1px solid #4f4949; }
  #product-area .product-details-wrapper .options, .quick-shop-content .product-details-wrapper .options {
    padding: 32px 0 12px; }
    #product-area .product-details-wrapper .options.unavailable, .quick-shop-content .product-details-wrapper .options.unavailable {
      padding: 20px 0; }
    @media (max-width: 719px) {
      #product-area .product-details-wrapper .options, .quick-shop-content .product-details-wrapper .options {
        border-top: 0px;
        padding-top: 5px;
        padding-bottom: 32px; } }
    #product-area .product-details-wrapper .options .field, .quick-shop-content .product-details-wrapper .options .field {
      position: relative;
      left: -4px;
      width: 46px;
      text-align: center; }
    #product-area .product-details-wrapper .options .select-wrapper,
    #product-area .product-details-wrapper .options .selector-wrapper, .quick-shop-content .product-details-wrapper .options .select-wrapper,
    .quick-shop-content .product-details-wrapper .options .selector-wrapper {
      margin-bottom: 20px; }
      #product-area .product-details-wrapper .options .select-wrapper.quantity,
      #product-area .product-details-wrapper .options .selector-wrapper.quantity, .quick-shop-content .product-details-wrapper .options .select-wrapper.quantity,
      .quick-shop-content .product-details-wrapper .options .selector-wrapper.quantity {
        margin-top: 15px; }
        #product-area .product-details-wrapper .options .select-wrapper.quantity > label,
        #product-area .product-details-wrapper .options .selector-wrapper.quantity > label, .quick-shop-content .product-details-wrapper .options .select-wrapper.quantity > label,
        .quick-shop-content .product-details-wrapper .options .selector-wrapper.quantity > label {
          margin-right: 20px; }
      @media (max-width: 719px) {
        #product-area .product-details-wrapper .options .select-wrapper.quantity,
        #product-area .product-details-wrapper .options .selector-wrapper.quantity, .quick-shop-content .product-details-wrapper .options .select-wrapper.quantity,
        .quick-shop-content .product-details-wrapper .options .selector-wrapper.quantity {
          margin-bottom: 15px; }
        #product-area .product-details-wrapper .options .select-wrapper.submit-wrapper,
        #product-area .product-details-wrapper .options .selector-wrapper.submit-wrapper, .quick-shop-content .product-details-wrapper .options .select-wrapper.submit-wrapper,
        .quick-shop-content .product-details-wrapper .options .selector-wrapper.submit-wrapper {
          margin-bottom: 15px; }
          #product-area .product-details-wrapper .options .select-wrapper.submit-wrapper input,
          #product-area .product-details-wrapper .options .selector-wrapper.submit-wrapper input, .quick-shop-content .product-details-wrapper .options .select-wrapper.submit-wrapper input,
          .quick-shop-content .product-details-wrapper .options .selector-wrapper.submit-wrapper input {
            width: 100%; } }
      #product-area .product-details-wrapper .options .select-wrapper .single-option-selector,
      #product-area .product-details-wrapper .options .selector-wrapper .single-option-selector, .quick-shop-content .product-details-wrapper .options .select-wrapper .single-option-selector,
      .quick-shop-content .product-details-wrapper .options .selector-wrapper .single-option-selector {
        width: 100%; }
        @media (max-width: 719px) {
          #product-area .product-details-wrapper .options .select-wrapper .single-option-selector,
          #product-area .product-details-wrapper .options .selector-wrapper .single-option-selector, .quick-shop-content .product-details-wrapper .options .select-wrapper .single-option-selector,
          .quick-shop-content .product-details-wrapper .options .selector-wrapper .single-option-selector {
            width: 100% !important; } }
      #product-area .product-details-wrapper .options .select-wrapper .pxuSexyDropWrapper,
      #product-area .product-details-wrapper .options .selector-wrapper .pxuSexyDropWrapper, .quick-shop-content .product-details-wrapper .options .select-wrapper .pxuSexyDropWrapper,
      .quick-shop-content .product-details-wrapper .options .selector-wrapper .pxuSexyDropWrapper {
        width: 50%; }
        @media (max-width: 719px) {
          #product-area .product-details-wrapper .options .select-wrapper .pxuSexyDropWrapper,
          #product-area .product-details-wrapper .options .selector-wrapper .pxuSexyDropWrapper, .quick-shop-content .product-details-wrapper .options .select-wrapper .pxuSexyDropWrapper,
          .quick-shop-content .product-details-wrapper .options .selector-wrapper .pxuSexyDropWrapper {
            width: 100% !important; } }
      #product-area .product-details-wrapper .options .select-wrapper .submit,
      #product-area .product-details-wrapper .options .selector-wrapper .submit, .quick-shop-content .product-details-wrapper .options .select-wrapper .submit,
      .quick-shop-content .product-details-wrapper .options .selector-wrapper .submit {
        margin-top: 12px; }
      #product-area .product-details-wrapper .options .select-wrapper.submit-wrapper label,
      #product-area .product-details-wrapper .options .selector-wrapper.submit-wrapper label, .quick-shop-content .product-details-wrapper .options .select-wrapper.submit-wrapper label,
      .quick-shop-content .product-details-wrapper .options .selector-wrapper.submit-wrapper label {
        display: none; }
    #product-area .product-details-wrapper .options .disabled, .quick-shop-content .product-details-wrapper .options .disabled {
      background: #5c5555;
      cursor: default;
      margin-top: 12px; }
    #product-area .product-details-wrapper .options.unavailable .disabled, .quick-shop-content .product-details-wrapper .options.unavailable .disabled {
      margin-top: 0; }
  #product-area .product-details-wrapper .inline-field-wrapper > label, .quick-shop-content .product-details-wrapper .inline-field-wrapper > label {
    font-size: 14px;
    color: #362c2c;
    margin-right: 20px;
    text-align: right;
    max-width: 40%;
    display: inline-block; }
    @media (max-width: 719px) {
      #product-area .product-details-wrapper .inline-field-wrapper > label, .quick-shop-content .product-details-wrapper .inline-field-wrapper > label {
        width: 100% !important;
        max-width: 100% !important;
        text-align: left;
        margin-right: 0;
        margin-bottom: 15px; } }
  #product-area .product-details-wrapper .js-required, .quick-shop-content .product-details-wrapper .js-required {
    display: none; }
    .js #product-area .product-details-wrapper .js-required, .js .quick-shop-content .product-details-wrapper .js-required {
      display: block; }
  .js #product-area .product-details-wrapper .no-js-required, .js .quick-shop-content .product-details-wrapper .no-js-required {
    display: none; }
  #product-area .product-details-wrapper .description, .quick-shop-content .product-details-wrapper .description {
    padding-top: 25px; }
    #product-area .product-details-wrapper .description.no-border, .quick-shop-content .product-details-wrapper .description.no-border {
      border-top: 0px; }
    #product-area .product-details-wrapper .description .go-to-product, .quick-shop-content .product-details-wrapper .description .go-to-product {
      font-size: 12px;
      color: #5d5b57; }
      .ie10 #product-area .product-details-wrapper .description .go-to-product span, .ie10 .quick-shop-content .product-details-wrapper .description .go-to-product span {
        font-weight: 600; }
      .lt-ie10 #product-area .product-details-wrapper .description .go-to-product span, .lt-ie10 .quick-shop-content .product-details-wrapper .description .go-to-product span {
        font-weight: 600; }
#product-area .product-details, .quick-shop-content .product-details {
  padding-top: 10px;
  text-align: center; }
#product-area .details.no-options .options, .quick-shop-content .details.no-options .options {
  padding: 20px 0 12px; }
  #product-area .details.no-options .options .selector-wrapper label, .quick-shop-content .details.no-options .options .selector-wrapper label {
    display: none; }
@media (max-width: 719px) {
  #product-area .details.no-options, .quick-shop-content .details.no-options {
    margin: 0;
    padding: 0; }
    #product-area .details.no-options .options, .quick-shop-content .details.no-options .options {
      padding: 30px 0; } }

.template-product .error-message {
  text-align: center;
  height: auto;
  padding: 10px; }
.template-product #product-area {
  position: relative;
  padding: 30px 0 60px; }
  @media (max-width: 719px) {
    .template-product #product-area {
      padding: 25px 0; } }
  .template-product #product-area .mobile-product-title {
    display: none;
    text-align: center;
    margin-top: 40px; }
    @media (max-width: 719px) {
      .template-product #product-area .mobile-product-title {
        display: block; } }
    .template-product #product-area .mobile-product-title .brand {
      color: #aeaaaa;
      font-size: 14px;
      margin: 0 0 0; }
    .template-product #product-area .mobile-product-title .title {
      font-size: 35px;
      margin: 5px 0 20px; }
    .template-product #product-area .mobile-product-title .price {
      font-size: 18px; }
    .template-product #product-area .mobile-product-title .share-buttons {
      margin: 30px 0; }
  .template-product #product-area .share-wrap {
    height: 25px;
    margin: 0 12px 10px 0;
    text-align: left;
    display: inline-block;
    vertical-align: top; }
    .template-product #product-area .share-wrap > iframe, .template-product #product-area .share-wrap > div {
      display: inline-block;
      vertical-align: top; }
    .template-product #product-area .share-wrap.first {
      border-top: 1px solid #5c5555; }
    .template-product #product-area .share-wrap.facebook {
      width: 61px; }
      .template-product #product-area .share-wrap.facebook .fb_iframe_widget span {
        vertical-align: top !important; }
    .template-product #product-area .share-wrap.twitter {
      width: 56px; }
    .template-product #product-area .share-wrap.google {
      width: 32px; }
    .template-product #product-area .share-wrap.pinterest {
      width: 39px; }
.template-product .related-products {
  margin-bottom: 80px; }

.fullscreen-product-viewer {
  display: none;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: url("//isabellaadams.com/cdn/shop/t/8/assets/ie-product-overlay-bg.png?v=130413172460849243661579199170") repeat 50% 50%;
  background: rgba(0, 0, 0, 0.5); }
  .fullscreen-product-viewer .modal {
    width: 72%;
    min-width: 690px;
    max-width: 1028px;
    margin: 0 auto;
    padding: 15px; }
    .fullscreen-product-viewer .modal.transitions-are-go {
      -webkit-transition: margin-top 0.3s linear;
      -moz-transition: margin-top 0.3s linear;
      transition: margin-top 0.3s linear; }
  .fullscreen-product-viewer .modal-wrap {
    position: relative;
    background-color: white;
    padding: 44px 30px;
    width: 100%; }
  .fullscreen-product-viewer .close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    color: #000000;
    cursor: pointer;
    line-height: 21px;
    text-indent: -5px;
    font-family: 'icons';
    font-size: 62px;
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30); }
    .fullscreen-product-viewer .close:hover {
      opacity: 0.4;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
      filter: alpha(opacity=40); }
  .fullscreen-product-viewer .showcase .wrap, .fullscreen-product-viewer .pager .wrap {
    position: relative; }
    .fullscreen-product-viewer .showcase .wrap .overlay, .fullscreen-product-viewer .pager .wrap .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      -webkit-transition: box-shadow 200ms;
      -moz-transition: box-shadow 200ms;
      transition: box-shadow 200ms; }
      .lt-ie9 .fullscreen-product-viewer .showcase .wrap .overlay, .lt-ie9 .fullscreen-product-viewer .pager .wrap .overlay {
        border: 1px solid #5c5555; }
    .fullscreen-product-viewer .showcase .wrap.active .overlay, .fullscreen-product-viewer .pager .wrap.active .overlay {
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3); }
    .fullscreen-product-viewer .showcase .wrap img, .fullscreen-product-viewer .pager .wrap img {
      width: 100%;
      height: auto; }
  .fullscreen-product-viewer .showcase {
    width: 100%;
    padding: 0 90px 0 0; }
    .fullscreen-product-viewer .showcase.wide {
      padding: 0; }
    .fullscreen-product-viewer .showcase .container {
      position: relative; }
      .fullscreen-product-viewer .showcase .container .spinner {
        position: absolute !important;
        left: 50% !important;
        top: 50% !important;
        z-index: 50 !important; }
    .fullscreen-product-viewer .showcase .wrap {
      z-index: 100; }
  .fullscreen-product-viewer .pager {
    position: absolute;
    top: 44px;
    right: 30px;
    width: 58px;
    -webkit-transition: height 0.3s linear;
    -moz-transition: height 0.3s linear;
    transition: height 0.3s linear; }
    .fullscreen-product-viewer .pager .wrap {
      margin-bottom: 20px; }
    .fullscreen-product-viewer .pager .wrap img {
      display: block; }
    .fullscreen-product-viewer .pager .overlay {
      cursor: pointer; }

.antiscroll-wrap {
  display: inline-block;
  position: relative;
  overflow: hidden; }

.antiscroll-scrollbar {
  background: gray;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-box-shadow: 0 0 1px #fff;
  -moz-box-shadow: 0 0 1px #fff;
  box-shadow: 0 0 1px #fff;
  position: absolute;
  opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  -webkit-transition: linear 300ms opacity;
  -moz-transition: linear 300ms opacity;
  -o-transition: linear 300ms opacity; }

.antiscroll-scrollbar-shown {
  opacity: 1;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100); }

.antiscroll-scrollbar-horizontal {
  height: 7px;
  margin-left: 2px;
  bottom: 2px;
  left: 0; }

.antiscroll-scrollbar-vertical {
  width: 7px;
  margin-top: 2px;
  right: 2px;
  top: 0; }

.antiscroll-inner {
  overflow: scroll;
  height: 100% !important; }

/** A bug in Chrome 25 on Lion requires each selector to have their own
blocks. E.g. the following:

.antiscroll-inner::-webkit-scrollbar, .antiscroll-inner::scrollbar {...}

causes the width and height rules to be ignored by the browser resulting
in both native and antiscroll scrollbars appearing at the same time.
*/
.antiscroll-inner::-webkit-scrollbar {
  width: 0;
  height: 0; }

.antiscroll-inner::scrollbar {
  width: 0;
  height: 0; }

.template-product .breadcrumb-navigation {
  margin: 0 auto;
  padding: 45px 0px 5px 0px; }

/* scss-combine:templates/search */
.template-search .results {
  width: 100%;
  padding-bottom: 50px; }
.template-search .results-label {
  font-size: 14px;
  font-style: italic;
  margin: 40px 0; }
  @media (max-width: 719px) {
    .template-search .results-label {
      margin: 0 0 40px;
      text-align: center; } }
.template-search .result {
  display: block;
  width: 66%;
  padding: 30px 0;
  border-top: 1px solid #4f4949;
  font-size: 0; }
  @media (max-width: 719px) {
    .template-search .result {
      width: 100%; } }
  .template-search .result.first {
    padding-top: 0 !important;
    border-top: 0; }
.template-search .result.item-article {
  padding: 30px 0 10px; }
  .template-search .result.item-article .title {
    font-weight: 700;
    font-size: 16px;
    color: #b38d41;
    margin: 0 0 12px; }
  .template-search .result.item-article .date {
    margin-bottom: 20px;
    color: #aeaaaa;
    font-size: 12px; }
  .template-search .result.item-article .item-image {
    margin-bottom: 12px; }
.template-search .result.item-product figure, .template-search .result.item-product .details {
  display: inline-block;
  vertical-align: top; }
.template-search .result.item-product figure {
  position: relative;
  width: 32%; }
  .template-search .result.item-product figure img {
    width: 100%; }
  .template-search .result.item-product figure a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2000; }
    .template-search .result.item-product figure a.overlay {
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.1); }
.template-search .result.item-product .details {
  width: 68%;
  padding-left: 30px; }
  .template-search .result.item-product .details .brand {
    font-size: 10px;
    margin: 5px 0 0; }
    .template-search .result.item-product .details .brand a {
      color: #aeaaaa; }
  .template-search .result.item-product .details .title {
    font-size: 16px;
    color: #5d5b57;
    margin: 14px 0 0; }
    .template-search .result.item-product .details .title a {
      color: #5d5b57; }
  .template-search .result.item-product .details .price {
    font-size: 14px;
    color: #362c2c;
    margin: 16px 0 0; }
    .template-search .result.item-product .details .price .label {
      color: #b38d41;
      margin-right: 5px; }
    .template-search .result.item-product .details .price .original {
      text-decoration: line-through;
      margin-right: 2px; }
.template-search .pagination {
  padding-bottom: 40px; }

.product-main-image .overlay {
  display: none; }

.instagram-photo img {
  width: 180px; }

.review-under-title .spr-header-title, .review-under-title .spr-summary-actions-newreview {
  display: none !important; }

.review-under-title .spr-container {
  border: none !important; }

.select-wrapper label.select-text {
  color: #000 !important; }

.mini-cart .quantity, .mini-cart .count, .template-cart .table td.quantity .field {
  color: black !important; }

.select-wrapper {
  color: #000 !important; }

.mini-cart .view-cart {
  background-color: #5d5b57; }

.banner {
  width: 100%;
  text-align: center;
  background-color: black;
  color: white; }

.banner p {
  color: white;
  padding: 5px 0 !important;
  margin: 0px; }

.main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .label, .main-header nav.full .nav-item .dropdown-wrap .dropdown-item a .icon {
  color: #ffffff !important; }

/* Atlantic - Modern-TH-IM-09-01-2018 - labeled text under each icon */
.template-page.text-content.has-sidebar, .template-contact.text-content.has-sidebar {
  float: none;
  width: auto;
  padding-right: 0px; }
